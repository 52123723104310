import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { CardNew } from '../../../../components/card/Card.jsx'
import { TabNew } from '../../../../components/tab/Tab.jsx'
import { getSegmentUrl } from '../../../../utilitys/helper'
import FormAuditProjectsGeneral from './FormAuditProjectsGeneral'
import FormAuditProjectsTeam from './FormAuditProjectsTeam'
import FormAuditProjectsDocument from './FormAuditProjectsDocument'
import FormAuditProjectsGeneralOrganizations from './FormAuditProjectsGeneralOrganizations'
import { ButtonNew } from '../../../../components/button/Button'
import { SAVE_AUDIT_PROJECT_GENERAL } from '../graphql/Mutation'
import { DATA_AUDITPROJECT_FORM_GENERAL_TAB } from '../graphql/Query'
import { useMutation, useLazyQuery } from '@apollo/client'
import { Alert } from '../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayError } from '../../../../utilitys/helper'

const FormAuditProjectsIndex = (props) => {
  /*
    -add- 
    -edit- state.refCode = projectCode
    -view- state.refCode = projectCode
  */
  const { t } = useTranslation()
  const type = getSegmentUrl(4)
  const { state } = useLocation()
  if (!state && type !== 'add') return <Navigate to={'/auditplanning/offplanprojects/'} />
  const params = getSegmentUrl(3)
  const navigate = useNavigate()

  const breadcrumbList = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/auditplan' },
    { label: t('แผนการตรวจสอบ'), path: '/auditplanning/auditplan' },
    { label: t('จัดการข้อมูลโครงการ'), path: '/auditplanning/auditplan' },
    { label: t('โครงการ') },
  ]

  const showAlertSuccess = (data) => {
    const dataContent = {
      content: '',
    }
    if (data) {
      if (data.detail === 'Please reapprove agian') {
        dataContent.content = t('กรุณาอนุมัติโครงการใหม่')
      }
    }
    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        if (type === 'add') {
          navigate('/auditplanning/offplanprojects/team/edit', {
            state: { refCode: formGeneral.getFieldValue('projectCode') },
          })
        } else {
          dataAuditProjectFormGeneralTab.refetch()
        }
      },
      ...dataContent,
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertOrgError = () => {
    Alert({
      type: 'error',
      title: t('โปรดเพิ่มหน่วยรับตรวจอย่างน้อย 1 หน่วย'),
      content: '',
      onOk() {},
    })
  }

  /// general form
  const [generalTabSubmit, setGeneralTabSubmit] = useState(0)
  const [organizations, setOrganizations] = useState([])
  const [riskForm, setRiskForm] = useState({ code: null, name: '' })
  const [formGeneral] = Form.useForm()
  const [saveDataFn, saveData] = useMutation(SAVE_AUDIT_PROJECT_GENERAL)

  useEffect(() => {
    if (saveData.data) {
      let response = saveData?.data?.auditProjectsGeneralSave
      if (response) {
        showAlertSuccess(response)
      }
    }
    if (saveData.error) {
      showAlertError(displayError(saveData.error))
    }
  }, [saveData.data])

  const [dataAuditProjectFormGeneralTabFn, dataAuditProjectFormGeneralTab] = useLazyQuery(
    DATA_AUDITPROJECT_FORM_GENERAL_TAB,
  )

  const showConfirmGeneral = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        if (organizations.length) {
          formGeneral.submit()
        } else {
          showAlertOrgError()
        }
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (dataAuditProjectFormGeneralTab?.data) {
      let response = dataAuditProjectFormGeneralTab?.data?.dataAuditProjectFormGeneralTab
      if (response) {
        setOrganizations(
          response.organization.map((item) => {
            return {
              organizationCode: item.organizationCode,
              organizationName:
                localStorage.getItem('lang') === 'th' ? item.organizationNameTH : item.organizationNameEN,
              organizationLevelName:
                localStorage.getItem('lang') === 'th' ? item.organizationLevelNameTH : item.organizationLevelNameEN,
              organizationNameTH: item.organizationNameTH,
              organizationNameEN: item.organizationNameEN,
              personnelCode: item.personnelCode,
              personnels:
                localStorage.getItem('lang') === 'th'
                  ? item.namePrefixTH + item.firstNameTH + ' ' + item.lastNameTH
                  : item.namePrefixEN + item.firstNameEN + ' ' + item.lastNameEN,
            }
          }),
        )
        setRiskForm({
          code: response.riskFormCode ? response.riskFormCode : null,
          name: localStorage.getItem('lang') === 'th' ? response.riskFormTH : response.riskFormEN,
        })
        formGeneral.setFieldsValue({
          cancelStatus: response.cancelStatus,
          cancelRemark: localStorage.getItem('lang') === 'th' ? response.cancelRemarkTH : response.cancelRemarkEN,
          projectCode: response.projectCode,
          projectName: localStorage.getItem('lang') === 'th' ? response.projectNameTH : response.projectNameEN,
          projectNameTH: response.projectNameTH,
          projectNameEN: response.projectNameEN,
          projectRefCode: response.projectRefCode,
          auditTypeCode: response.auditTypeCode,
          approveStatusName:
            localStorage.getItem('lang') === 'th' ? response.approveStatusNameTH : response.approveStatusNameEN,
          projectStatusName:
            localStorage.getItem('lang') === 'th' ? response.projectStatusNameTH : response.projectStatusNameEN,
          isActive: response.isActive,
          approvePersonnelCode: response.approvePersonnelCode,
          riskFormCode: response.riskFormCode
            ? response.riskFormCode +
              ' : ' +
              (localStorage.getItem('lang') === 'th' ? response.riskFormTH : response.riskFormEN)
            : '',
        })
      }
    }
    if (dataAuditProjectFormGeneralTab.error) {
      showAlertLoadDataError(displayError(dataAuditProjectFormGeneralTab.error))
    }
  }, [dataAuditProjectFormGeneralTab.data])

  useEffect(() => {
    if (generalTabSubmit) {
      showConfirmGeneral()
    }
  }, [generalTabSubmit])

  const onFinishGeneral = async (value) => {
    let input = {
      menuCode: 'AP03',
      projectTypeCode: '20',
      projectCode: value.projectCode,
      projectRefCode: value.projectRefCode,
      riskFormCode: riskForm.code,
      organizationCode: organizations,
      isActive: value.isActive,
      auditTypeCode: value.auditTypeCode,
      approvePersonnelCode: value.approvePersonnelCode,
    }

    if (type === 'add') {
      input['projectNameTH'] = value.projectName
      input['projectNameEN'] = value.projectName
      input['action'] = 'create'
    } else if (type === 'edit') {
      input['projectNameTH'] = localStorage.getItem('lang') === 'th' ? value.projectName : value.projectNameTH
      input['projectNameEN'] = localStorage.getItem('lang') === 'en' ? value.projectName : value.projectNameEN
      input['action'] = 'update'
    }
    saveDataFn({
      variables: {
        input: encryptInput(input),
      },
      fetchPolicy: 'no-cache',
    })
  }

  /// end general form
  useEffect(() => {
    if (type !== 'add' && params === 'general') {
      dataAuditProjectFormGeneralTabFn({
        fetchPolicy: 'no-cache',
        variables: {
          projectCode: encryptInput(state.refCode),
        },
      })
    }
  }, [type, params])

  const tabs = [
    {
      key: 1,
      iconTab: () => <></>,
      name: t('ข้อมูลทั่วไปโครงการ'),
      disabled: type === 'add' ? true : false,
      content: () =>
        params === 'general' ? (
          <FormAuditProjectsGeneral
            formGeneral={formGeneral}
            onFinish={onFinishGeneral}
            riskForm={riskForm}
            setRiskForm={setRiskForm}
            menu={props.menu}
            typeForm={type}
            refCode={type !== 'add' ? state.refCode : ''}
            organizations={organizations}
            generalTabSubmit={generalTabSubmit}
          />
        ) : (
          ''
        ),
    },
    {
      key: 2,
      iconTab: () => <></>,
      name: t('ผู้รับผิดชอบ/ระยะเวลา'),
      disabled: type === 'add' ? true : false,
      content: () =>
        params === 'team' ? (
          <FormAuditProjectsTeam menu={props.menu} typeForm={type} refCode={type !== 'add' ? state.refCode : ''} />
        ) : (
          ''
        ),
    },
    {
      key: 3,
      iconTab: () => <></>,
      name: t('เอกสารเพิ่มเติม'),
      disabled: type === 'add' ? true : false,
      content: () =>
        params === 'document' ? (
          <FormAuditProjectsDocument menu={props.menu} typeForm={type} refCode={type !== 'add' ? state.refCode : ''} />
        ) : (
          ''
        ),
    },
  ]

  return (
    <>
      {(saveData.loading || dataAuditProjectFormGeneralTab.loading) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('โครงการ')} menu={props.menu} />
      <Row>
        <Col md={24}>
          <CardNew
            topic={'โครงการ'}
            icon={type === 'add' ? 'Plus' : type === 'edit' ? 'Edit2' : 'FileText'}
            toggledrop="false"
          >
            <TabNew
              tab={tabs}
              activeKey={params === 'general' ? '1' : params === 'team' ? '2' : params === 'document' ? '3' : ''}
              onTabClick={(key) => {
                if (key === '1') {
                  navigate('/auditplanning/offplanprojects/general/' + type, {
                    state: { refCode: type !== 'add' ? state.refCode : '' },
                  })
                } else if (key === '2') {
                  navigate('/auditplanning/offplanprojects/team/' + type, {
                    state: { refCode: type !== 'add' ? state.refCode : '' },
                  })
                } else if (key === '3') {
                  navigate('/auditplanning/offplanprojects/document/' + type, {
                    state: { refCode: type !== 'add' ? state.refCode : '' },
                  })
                }
              }}
            />
          </CardNew>
        </Col>
      </Row>
      {params === 'general' ? (
        <Row>
          <Col md={24}>
            <CardNew topic={t('หน่วยงานรับตรวจ')} icon="AlignJustify" toggledrop="false">
              <FormAuditProjectsGeneralOrganizations
                typeForm={type}
                refCode={type !== 'add' ? state.refCode : ''}
                organizations={organizations}
                setOrganizations={setOrganizations}
                menu={props.menu}
              />
              <Row gutter={[12, 12]} justify="center">
                {type !== 'view' && (
                  <Col>
                    <ButtonNew
                      type="primary"
                      roles={{ type: type, menu: props.menu }}
                      onClick={() => {
                        setGeneralTabSubmit(generalTabSubmit + 1)
                      }}
                      htmlType="submit"
                    >
                      {t('บันทึก')}
                    </ButtonNew>
                  </Col>
                )}
                <Col>
                  <ButtonNew
                    type="cancel"
                    onClick={() => {
                      navigate('/auditplanning/offplanprojects/list')
                    }}
                  >
                    {t('ยกเลิก')}
                  </ButtonNew>
                </Col>
              </Row>
            </CardNew>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  )
}

export default FormAuditProjectsIndex
