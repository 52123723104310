import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Col, Table, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_AUDIT_RESULTS,
  GET_AUDIT_RESULT_DETAIL,
  GET_PERSONNEL_ORGANIZATION,
  GET_AUDIT_RCMS_BY_ID,
} from '../../graphql/Query'
import { DELETE_AUDIT_RCM_RESULT } from '../../graphql/Mutation'
import { ButtonNew } from '../../../../../components/button/Button'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { SearchTable } from '../../../../../components/searchtable/SearchTable'
import { IconNew } from '../../../../../components/icon/Icon'
import { Alert } from '../../../../../components/alert/Alert'
import { Status } from '../../../../../components/status/Status'
import { encryptInput } from '../../../../../utilitys/crypto'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { handleSort } from '../../../../../utilitys/pagination'
import PersonnelRCMFormModal from '../modal/PersonnelRCMFormModal'
import RateFacGroupModal from '../modal/RateFacGroupModal'
import RiskModal from '../modal/RiskModal'

const RiskTab = (props) => {
  const wrapper = { md: 24, lg: 24, xl: 24 }

  const { menu } = props

  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()
  const [user] = useDecodeUser()

  if (!state) return <Navigate to={'/auditoperations/racmatrix'} />

  const [isDataSources, setIsDataSources] = useState([])
  const [dataLists, setDataLists] = useState([])
  const [dataListDetail, setDataListDetail] = useState([])
  const [sort, setSort] = useState([])
  const [isRcmResultId, setIsRcmResultId] = useState(null)
  const [modal, setModal] = useState(false)
  const [actionPersonnelRCMFormModal, setActionPersonnelRCMFormModal] = useState(null)
  const [idActionPersonnelRCMForm, setIdActionPersonnelRCMForm] = useState({})
  const [columns, setColumns] = useState([])
  const [isUserId, setIsUserId] = useState(null)
  const [isOrgCode, setIsOrgCode] = useState(null)
  const [isPCode, setIsPCode] = useState(null)
  const [modalRateFac, setModalRateFac] = useState(false)
  const [isRateFacGroupName, setIsRateFacGroupName] = useState(null)
  const [rcmResultId, setRcmResultId] = useState(null)
  const [rateFacGroupDetail, setRateFacGroupDetail] = useState(null)
  const [isPersonnelOrg, setIsPersonnelOrg] = useState(null)
  const [isCanEstimate, setIsCanEstimate] = useState(false)
  const [isCanEdit, setIsCanEdit] = useState(false)
  const [modalRisk, setModalRisk] = useState(false)
  const [personnelMaker, setPersonnelMaker] = useState(null)

  const [getAuditResultsFn, getAuditResults] = useLazyQuery(GET_AUDIT_RESULTS)
  const [getAuditResultDetailFn, getAuditResultDetail] = useLazyQuery(GET_AUDIT_RESULT_DETAIL)
  const [deleteAuditRCMResultFn, deleteAuditRCMResult] = useMutation(DELETE_AUDIT_RCM_RESULT)
  const [getPersonnelOrganizationFn, getPersonnelOrganization] = useLazyQuery(GET_PERSONNEL_ORGANIZATION)
  const [getAuditRCMFn, getAuditRCM] = useLazyQuery(GET_AUDIT_RCMS_BY_ID)

  useEffect(() => {
    if (user?.userId) setIsUserId(user?.userId)
    if (user?.orgCode) setIsOrgCode(user?.orgCode)
    if (user?.pCode) setIsPCode(user?.pCode)
  }, [user?.userId, user?.orgCode, user?.pCode])

  useEffect(() => {
    if (state?.rcmId) {
      getAuditRCMFn({ variables: { rcmId: encryptInput(state?.rcmId) } })
    }
  }, [state?.rcmId])

  useEffect(() => {
    if (isRcmResultId) {
      getAuditResultDetailFn({ variables: { input: encryptInput({ rcmResultId: isRcmResultId }) } })
    }
  }, [isRcmResultId])

  useEffect(() => {
    if (isOrgCode) {
      getPersonnelOrganizationFn({
        variables: {
          input: encryptInput({
            organizationCode: isOrgCode,
            personnelCode: isPCode,
            organizationChecked: 2,
          }),
        },
      })
    }
  }, [isOrgCode, isPCode])

  useEffect(() => {
    getAuditResultsFn({
      variables: {
        input: encryptInput({
          filters: { projectCode: state?.projectCode },
          pagination: { limit: 10, page: 1 },
          sort,
        }),
      },
    })
  }, [state?.projectCode, sort])

  useEffect(() => {
    if (getPersonnelOrganization?.data) {
      setIsPersonnelOrg(
        getPersonnelOrganization?.data?.getPersonnelOrganization.length
          ? getPersonnelOrganization?.data?.getPersonnelOrganization[0]
          : null,
      )
    }
    if (getPersonnelOrganization?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getPersonnelOrganization?.error)),
        onOk() {},
      })
    }
  }, [getPersonnelOrganization?.data])

  useEffect(() => {
    if (getAuditRCM?.data) {
      const dataRCM = getAuditRCM?.data?.getAuditRCMsById?.auditRCMs

      setPersonnelMaker(dataRCM.personnelMaker.toLowerCase())

      /* ผู้สร้างสามารถแก้ไขแบบประเมินได้ */
      if (parseInt(isUserId) === parseInt(dataRCM?.createdBy)) {
        setIsCanEdit(true)
      } else {
        setIsCanEdit(false)
      }

      if (isPersonnelOrg) {
        /* คนๆ นี้ต้องเป็น ผู้จัดทำ หรือ ผู้สอบทาน */
        /* จึงจะสามารทำแบบประเมิน */
        if (
          isPersonnelOrg?.personnelCode.toLowerCase() === dataRCM.personnelMaker.toLowerCase() ||
          isPersonnelOrg?.personnelCode.toLowerCase() === dataRCM.personnelReview.toLowerCase() ||
          parseInt(isPersonnelOrg?.isUserId) === parseInt(dataRCM?.createdBy)
        ) {
          setIsCanEstimate(true)
        } else {
          setIsCanEstimate(false)
        }
      }
    }

    if (getAuditRCM?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditRCM?.error)),
        onOk() {},
      })
    }
  }, [getAuditRCM?.data, isPersonnelOrg, isUserId])

  useEffect(() => {
    if (getAuditResults?.data) {
      const results = getAuditResults?.data?.getAuditResults

      const ansResults = _.map(results, (d, i) => {
        const personnelRCMName = displayText(d?.personnelRCMNameTH, d?.personnelRCMNameEN)

        const rateFacGroupName = displayText(d.rateFacGroupNameTH, d.rateFacGroupNameEN)

        return {
          ...d,
          key: d.rcmResultId,
          no: i + 1,
          rateFacProName: displayText(d.rateFacProNameTH, d.rateFacProNameEN),
          rateStatus: d.rateStatus ? 1 : 0,
          likelihood: d.likelihood
            ? d.likelihood
            : d.likelihood === null || d.likelihood === undefined
            ? '-'
            : d.likelihood,
          impact: d.impact ? d.impact : d.impact === null || d.impact === undefined ? '-' : d.impact,
          riskScore: d.riskScore ? d.riskScore : d.riskScore === null || d.riskScore === undefined ? '-' : d.riskScore,
          personnelName: personnelRCMName ? personnelRCMName : '-',
          shortCode: d.shortCode,
          mainRecord: true,
          rateFacGroupName: d.rateFacGroupDetail ? d.rateFacGroupDetail : rateFacGroupName,
        }
      })

      setDataLists(ansResults)
      setIsDataSources([...ansResults])
    }

    if (getAuditResults?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditResults?.error)),
        onOk() {},
      })
    }
  }, [getAuditResults?.data])

  useEffect(() => {
    if (getAuditResultDetail?.data) {
      const results = getAuditResultDetail?.data?.getAuditResultDetail
      const ansResultDetail = _.map(results, (d) => {
        return {
          ...d,
          key: uuidv4(),
          no: '',
          rateFacProName: displayText(d.riskFactorNameTH, d.riskFactorNameEN),
          rateStatus: '',
          likelihood: d.likelihood ? d.likelihood : '-',
          impact: d.impact ? d.impact : '-',
          riskScore: d.riskScore ? d.riskScore : '-',
          personnelName: '',
          shortCode: '',
          mainRecord: false,
        }
      })
      setDataListDetail(ansResultDetail)
    }

    if (getAuditResultDetail?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditResultDetail?.error)),
        onOk() {},
      })
    }
  }, [getAuditResultDetail?.data])

  useEffect(() => {
    if (dataLists.length && dataListDetail.length) {
      const groupResults = _.groupBy(dataListDetail, 'rcmResultId')

      const newDataLists = _.map(dataLists, (d) => {
        const getGroupResults = groupResults[d.key]
        return {
          ...d,
          children: getGroupResults ? getGroupResults : [],
        }
      })

      setIsDataSources([...newDataLists])
    }
  }, [dataLists, dataListDetail])

  useEffect(() => {
    if (deleteAuditRCMResult?.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getAuditResults.refetch()
        },
      })
    }

    if (deleteAuditRCMResult?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(deleteAuditRCMResult?.error)),
        onOk() {},
      })
    }
  }, [deleteAuditRCMResult?.data])

  const calculateScore = (score) => {
    if (score === 1) return 'L'
    if (score === 2) return 'ML'
    if (score === 3) return 'M'
    if (score === 4) return 'MH'
    if (score === 5) return 'H'
    if (score === '-') return score
  }

  const calculateRiskScore = (score) => {
    if (score >= 1 && score <= 5) return 'L'
    if (score >= 6 && score <= 10) return 'ML'
    if (score >= 11 && score <= 15) return 'M'
    if (score >= 16 && score <= 20) return 'MH'
    if (score >= 21) return 'H'
    if (score === '-') return score
  }

  const onDeleteAuditRCMResult = (rcmResultId) => {
    if (rcmResultId) {
      Alert({
        type: 'confirm',
        title: t('กรุณากดยืนยันหากต้องการลบข้อมูล'),
        onOk() {
          deleteAuditRCMResultFn({ fetchPolicy: 'no-cache', variables: encryptInput({ rcmResultId: rcmResultId }) })
        },
        onCancel() {},
      })
    }
  }

  useEffect(() => {
    const initColumns = [
      { title: '', dataIndex: 'expand', width: '40px', align: 'center', fixed: 'left' },
      {
        title: t('ลำดับ'),
        dataIndex: 'no',
        width: '80px',
        align: 'center',
        sorter: (a, b) => a.no - b.no,
        fixed: 'left',
      },
      {
        title: t('กลุ่มความเสี่ยงกระบวนการ'),
        dataIndex: 'rateFacGroupName',
        width: '300px',
        fixed: 'left',
        align: 'left',
        // sorter: { multiple: 1 },
        render: (text, record) => {
          const lists = [
            {
              type: 'edit',
              onClick: async () => {
                await setIsRateFacGroupName(record?.rateFacGroupName)
                await setRateFacGroupDetail(record?.rateFacGroupDetail)
                await setRcmResultId(record?.rcmResultId)
                await setModalRateFac(true)
              },
            },
          ]

          return (
            <Row>
              <Col sm={22}>{`${record.mainRecord ? `${record?.rateFacGroupName}` : ''}`}</Col>
              <Col sm={2}>{record.mainRecord && isCanEdit && <ButtonGroup menu={menu} size={18} icons={lists} />}</Col>
            </Row>
          )
        },
      },
      {
        title: t('ปัจจัย/ความเสี่ยง'),
        dataIndex: 'rateFacProName',
        width: '450px',
        align: 'left',
        render: (text, render) => {
          return (
            <Row>
              <Col sm={2}>{`${render.mainRecord ? `${render.shortCode}: ` : ''}`}</Col>
              <Col sm={22}>{text}</Col>
            </Row>
          )
        },
      },
      {
        title: t('สถานะประเมิน'),
        dataIndex: 'rateStatus',
        width: '120px',
        align: 'center',
        render: (text, record) => (record.mainRecord ? (text === 1 ? t('ประเมินแล้ว') : t('ยังไม่ประเมิน')) : ''),
      },
      {
        title: t('ผลการประเมินความเสี่ยง'),
        children: [
          {
            title: t('โอกาส'),
            dataIndex: 'likelihood',
            align: 'center',
            key: 'likelihood',
            width: '120px',
            render: (text, record) => (record.mainRecord ? calculateScore(text) : text),
          },
          {
            title: t('ผลกระทบ'),
            dataIndex: 'impact',
            align: 'center',
            key: 'impact',
            width: '120px',
            render: (text, record) => (record.mainRecord ? calculateScore(text) : text),
          },
          {
            title: t('ระดับความเสี่ยง'),
            dataIndex: 'riskScore',
            align: 'center',
            key: 'riskScore',
            width: '120px',
            render: (text, record) => (record.mainRecord ? calculateRiskScore(text) : text),
          },
        ],
      },
      {
        title: t('ผู้รับผิดชอบ'),
        dataIndex: 'personnelName',
        width: '200px',
        align: 'center',
        render: (text, record) => (record.mainRecord ? text : ''),
      },
      {
        title: t('สถานะอนุมัติ'),
        dataIndex: 'approveStatusCode',
        align: 'center',
        width: '150px',
        render: (d, record) => <Status text={displayText(record?.approveStatusNameTH, record?.approveStatusNameEN)} />,
      },
      {
        title: '',
        dataIndex: 'buttonAction',
        align: 'center',
        render: (text, record) => {
          if (record.mainRecord && record.rateStatus === 0 && isCanEstimate) {
            return (
              <ButtonNew
                type="flagOutlinedInTableBlue"
                roles={{ type: 'add', menu: menu }}
                onClick={() =>
                  navigate(`/auditoperations/racmatrix/tab/risk/form`, {
                    state: { ...state, rcmResultId: record?.rcmResultId, riskForm: 'create' },
                  })
                }
              >
                {t('เริ่มประเมิน')}
              </ButtonNew>
            )
          }
          if (record.mainRecord && record.rateStatus === 1 && isCanEstimate) {
            return (
              <ButtonNew
                type="moreHorizontalInTableGray"
                roles={{ type: 'edit', menu: menu }}
                onClick={() =>
                  navigate(`/auditoperations/racmatrix/tab/risk/form`, {
                    state: { ...state, rcmResultId: record?.rcmResultId, riskForm: 'edit' },
                  })
                }
              >
                {t('แก้ไขการประเมิน')}
              </ButtonNew>
            )
          }
        },
      },
      {
        title: '',
        dataIndex: 'tools',
        align: 'left',
        render: (text, record) => {
          let list = [
            {
              type: 'view',
              onClick: async () => {
                await setActionPersonnelRCMFormModal('view')
                await setIdActionPersonnelRCMForm({
                  rcmId: record?.rcmId,
                  rcmResultId: record?.rcmResultId,
                  rateFacProName: `${record?.shortCode}: ${record?.rateFacProName}`,
                })
                await setModal(true)
              },
            },
            {
              type: 'edit',
              onClick: async () => {
                await setActionPersonnelRCMFormModal('edit')
                await setIdActionPersonnelRCMForm({
                  rcmId: record?.rcmId,
                  rcmResultId: record?.rcmResultId,
                  rateFacProName: `${record?.shortCode}: ${record?.rateFacProName}`,
                })
                await setModal(true)
              },
            },
            { type: 'delete', onClick: () => onDeleteAuditRCMResult(record?.rcmResultId) },
          ]
          return record.mainRecord && isCanEdit && <ButtonGroup menu={menu} size={18} icons={list} />
        },
      },
    ]

    setColumns(
      state?.typeAction === 'view'
        ? _.filter(initColumns, (c) => c.dataIndex !== 'buttonAction' && c.dataIndex !== 'tools')
        : initColumns,
    )
  }, [state?.typeAction, isCanEdit, isCanEstimate])

  return (
    <>
      {(getAuditResults?.loading || getAuditResultDetail?.loading || deleteAuditRCMResult?.loading) && <SpinnersNew />}
      <Row justify="center">
        <Col {...wrapper}>
          <SearchTable
            dataSource={dataLists}
            searchDataSource={(data) => setIsDataSources(data)}
            searchCustom={true}
            btnAdd={
              isCanEdit ? (
                <ButtonNew
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: menu }}
                  onClick={async () => {
                    await setModalRisk(true)
                  }}
                >
                  {t('เพิ่มประเมินความเสี่ยง')}
                </ButtonNew>
              ) : (
                <></>
              )
            }
          />
          <Table
            columns={columns}
            dataSource={[...isDataSources]}
            size="middle"
            pagination={false}
            showSorterTooltip={false}
            scroll={{ x: 'max-content', y: 450 }}
            onChange={(pagination, filters, sorter) => {
              const reNewField = [
                { rateFacProName: displayText('rateFacProNameTH', 'rateFacProNameEN') },
                { rateFacGroupName: displayText('rateFacGroupNameTH', 'rateFacGroupNameEN') },
                { personnelName: displayText('personnelNameTH', 'personnelNameEN') },
              ]
              setSort(handleSort(sorter, reNewField))
            }}
            expandable={{
              childrenColumnName: 'children',
              onExpandedRowsChange: (e) => setIsRcmResultId(e),
              expandIcon: ({ expanded, onExpand, record }) =>
                record.mainRecord ? (
                  expanded ? (
                    <IconNew icon={'ChevronUp'} onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <IconNew icon={'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                  )
                ) : (
                  <></>
                ),
            }}
          />
          <Row gutter={[12, 20]} justify="center" style={{ marginTop: '2rem' }}>
            <Col>
              <ButtonNew type="back" onClick={() => navigate('/auditoperations/racmatrix')}>
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </Col>
      </Row>
      {modal && (
        <PersonnelRCMFormModal
          open={modal}
          menu={props.menu}
          close={(e) => setModal(e)}
          getData={() => getAuditResults.refetch()}
          actionModal={actionPersonnelRCMFormModal}
          idActionPersonnelRCMForm={idActionPersonnelRCMForm}
        />
      )}
      {modalRateFac && (
        <RateFacGroupModal
          open={modalRateFac}
          menu={props.menu}
          close={(e) => setModalRateFac(e)}
          getData={() => getAuditResults.refetch()}
          isRateFacGroupName={isRateFacGroupName}
          rateFacGroupDetail={rateFacGroupDetail}
          rcmResultId={rcmResultId}
        />
      )}
      {modalRisk && (
        <RiskModal
          open={modalRisk}
          menu={props.menu}
          action={'add'}
          close={(e) => setModalRisk(e)}
          personnelMaker={personnelMaker}
          getData={() => getAuditResults.refetch()}
        />
      )}
    </>
  )
}

RiskTab.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default RiskTab
