import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { LabelNew as Label } from '../../../../components/label/Label'
import { ButtonNew as Button } from '../../../../components/button/Button'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { InputNew } from '../../../../components/input/Input'
import { TextAreaNew as TextArea } from '../../../../components/textarea/TextArea'
import SelectProjectTypes from '../../../../components/inputfromapi/selectprojecttypes/SelectProjectTypes'
import SelectPlan from '../../../../components/inputfromapi/selectplan/SelectPlan'
import SelectAuditProjects from '../../../../components/inputfromapi/selectauditprojects/SelectAuditProjects'
import SelectActionPlanOrganizations from '../../../../components/inputfromapi/selectactionplanorganizations/SelectActionPlanOrganizations'
import SelectPersonnels from '../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import SelectActionPlanPersonnel from '../../../../components/inputfromapi/selectactionplanpersonnel/SelectActionPlanPersonnel'

import { TableFile, FormDisplayFlex } from '../css/Styles'
import { UploadNew as Upload } from '../../../../components/upload/Upload'
import { ButtonNew } from '../../../../components/button/Button'
import { displayText } from '../../../../utilitys/helper'
import { Alert } from '../../../../components/alert/Alert'
import { GET_ACTIONPLAN_PERSONNEL, SELECT_PERSONNEL, DATA_AUDIT_OPENS_INFORMATION_TAB } from '../graphql/Query'
import { SAVE_AUDIT_OPEN, AUDITOPENS_GENERATECODE } from '../graphql/Mutation'

import { useLazyQuery, useMutation } from '@apollo/client'
import { encryptInput, decryptData } from '../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { useNavigate } from 'react-router-dom'
import { displayError } from '../../../../utilitys/helper'

const FormAuditOpensInformation = (props) => {
  const [typeForm, settypeForm] = useState(props?.typeForm)
  const [refCode, setrefCode] = useState(props?.refCode)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [file, setFile] = useState([])
  const [idFileDefault, setIdFileDefault] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const [disabledRunNumber, setDisabledRunNumber] = useState(false)

  const [projectTypeCode, setProjectTypeCode] = useState('10')
  const [planCode, setPlanCode] = useState(null)
  const [projectCode, setProjectCode] = useState(null)
  const [sendForCopy, setSendForCopy] = useState('noselect')
  const [sendForCopyMaster, setSendForCopyMaster] = useState([])
  const [openNumber, setOpenNumber] = useState('')
  const [editable, seteditable] = useState(true)

  useEffect(() => {
    if (props.typeForm === 'view' || props.typeForm === 'edit') {
      seteditable(false)
      settypeForm(props.typeForm)
    }
  }, [props.typeForm])

  useEffect(() => {
    if (props?.refCode) {
      setrefCode(props?.refCode)
    }
  }, [props?.refCode])

  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }
  const validDate = () => {
    let valid = false
    if (form.getFieldValue('dateStart') && form.getFieldValue('dateEnd')) {
      let dateStart = new Date(form.getFieldValue('dateStart'))
      let dateEnd = new Date(form.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      valid = true
    }
    return valid
  }
  const showAlertSuccess = (openId) => {
    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        if (typeForm === 'add') {
          // settypeForm('edit')
          setrefCode(openId)
          seteditable(false)
          navigate('/auditoperations/auditopens/information/edit', {
            state: { refCode: openId },
          })
        }
      },
    })
  }

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {
        navigate('/auditoperations/auditopens')
      },
    })
  }

  const showConfirm = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        const valid = validDate()
        if (
          valid &&
          form.getFieldsError().filter((item) => {
            return item.errors.length !== 0
          }).length === 0
        ) {
          form.submit()
        }
      },
      onCancel() {},
    })
  }

  const [dataAuditOpensInformationTabFn, dataAuditOpensInformationTab] = useLazyQuery(DATA_AUDIT_OPENS_INFORMATION_TAB)

  useEffect(() => {
    if (dataAuditOpensInformationTab?.data) {
      let response = dataAuditOpensInformationTab?.data?.dataAuditOpensInformationTab

      if (response) {
        setDisabledRunNumber(response.openNumber ? true : false)
        setProjectTypeCode(response.projectTypeCode)
        setPlanCode(response.planCode)
        setProjectCode(response.projectCode)
        setSendForCopy(
          response?.sendForCopy
            .map((item) => {
              return "'" + item.personnelCode + "'"
            })
            .join(),
        )
        setIdFileDefault(
          response?.files?.split(',')?.map((item) => {
            return parseInt(item)
          }),
        )
        const fileDetail = JSON.parse(response.fileDetail)
        const uniqSendFor = response.sendFor.map((item) => {
          return {
            organizationCode: item.organizationCode,
            openId: item.openId,
            openToType: item.openToType,
            personnelCode: item.personnelCode,
            email: item.email,
            value: item?.organizationCode,
            source: item,
          }
        })
        // setOpenNumber(response.openNumber ? response.openNumber : '') // ที่ comment ไว้ เพราะตอนแรกปรับแก้ไว้ถ้ามีเลขที่หนังสือเปิดตรวจ จะไม่สามารถให้แก้ไขได้ แต่ตอนนีเขาอยากให้ปิดเพื่อแก้ได้ก่อน
        const uniqSendForCopy = response.sendForCopy.map((item) => {
          return item.personnelCode
        })
        form.setFieldsValue({
          openNumber: response.openNumber,
          openDate: response.openDate,
          projectTypeCode: response.projectTypeCode,
          planCode: response.planCode,
          projectCode: response.projectCode,
          organizationCode: response.organizationCode,
          organizationName:
            response.organizationCode + ' : ' + displayText(response.organizationNameTH, response.organizationNameEN),
          openFor: displayText(response.openForTH, response.openForEN),
          openSubject: displayText(response.openSubjectTH, response.openSubjectEN),
          sendFor: uniqSendFor,
          sendForCopy: uniqSendForCopy,
          openInform: displayText(response.openInformTH, response.openInformEN),
          openPost: displayText(response.openPostTH, response.openPostEN),
          openSigner: response.openSigner,
          openReview: response.openReview,
          openCoordinate: response.openCoordinate,
          openCoordinateTel: decryptData(response.openCoordinateMobile)
            ? decryptData(response.openCoordinateMobile)
            : response.openCoordinateTelephone,
          dateStart: response.dateStart,
          dateEnd: response.dateEnd,
          docName: fileDetail ? fileDetail.name : '',
          link: fileDetail ? fileDetail.link : '',
        })
        setOpenNumber(response.openNumber)
      }
    }
  }, [dataAuditOpensInformationTab.data])

  const [auditOpensGenerateCodeFn, auditOpensGenerateCode] = useMutation(AUDITOPENS_GENERATECODE)
  useEffect(() => {
    if (auditOpensGenerateCode?.data) {
      let response = auditOpensGenerateCode?.data?.auditOpensGenerateCode
      if (response) {
        form.setFieldsValue({ openNumber: response.codeGen })
        setDisabledRunNumber(response.codeGen ? true : false)
      }
    }
  }, [auditOpensGenerateCode.data])
  const [auditOpensInformationSaveFn, auditOpensInformationSave] = useMutation(SAVE_AUDIT_OPEN)
  useEffect(() => {
    if (auditOpensInformationSave?.data) {
      let response = auditOpensInformationSave?.data?.auditOpensInformationSave
      if (response) {
        showAlertSuccess(response.openId)
        if (response?.openNumber) {
          setDisabledRunNumber(true)
          // setOpenNumber(response.openNumber)
          form.setFieldsValue({ openNumber: response.openNumber })
        }
      }
    }
  }, [auditOpensInformationSave.data])

  const [personnelSelectFn, personnelSelect] = useLazyQuery(SELECT_PERSONNEL)
  useEffect(() => {
    if (personnelSelect?.data) {
      let response = personnelSelect?.data?.personnelSelect
      if (response) {
        setSendForCopyMaster(
          response.map((item) => {
            return {
              organizationCode: item.organizationCode,
              personnelCode: item.personnelCode,
              email: item.email,
            }
          }),
        )
      }
    }
  }, [personnelSelect.data])

  const [getActionPlanPersonnelForAuditOpenFn, getActionPlanPersonnelForAuditOpen] =
    useLazyQuery(GET_ACTIONPLAN_PERSONNEL)

  useEffect(() => {
    if (getActionPlanPersonnelForAuditOpen?.data) {
      let response = getActionPlanPersonnelForAuditOpen?.data?.getActionPlanPersonnelForAuditOpen

      if (response) {
        const uniqResponse = _.uniqBy(
          response,
          (items) =>
            displayText(items.firstNameTH, items.firstNameEN) && displayText(items.lastNameTH, items.lastNameEN),
        )
        form.setFieldsValue({
          actionPlanPersonnel: uniqResponse.map((item) => {
            return item.actPlanPersonId
          }),
        })
      }
    }
  }, [getActionPlanPersonnelForAuditOpen.data])

  useEffect(() => {
    getActionPlanPersonnelForAuditOpenFn({
      variables: { input: encryptInput({ projectCode: projectCode }) },
    })
  }, [projectCode])

  useEffect(() => {
    personnelSelectFn({
      variables: { input: encryptInput({ personnelCodeWhereIn: sendForCopy }) },
    })
  }, [sendForCopy])

  useEffect(() => {
    if (typeForm !== 'add' && refCode) {
      dataAuditOpensInformationTabFn({
        variables: { openId: encryptInput(refCode) },
      })
    }
  }, [typeForm, refCode])

  useEffect(() => {
    if (getActionPlanPersonnelForAuditOpen.error) {
      showAlertLoadDataError(displayError(getActionPlanPersonnelForAuditOpen.error))
    }
    if (personnelSelect.error) {
      showAlertLoadDataError(displayError(personnelSelect.error))
    }
    if (auditOpensInformationSave.error) {
      showAlertError(displayError(auditOpensInformationSave.error))
    }
    if (dataAuditOpensInformationTab.error) {
      showAlertLoadDataError(displayError(dataAuditOpensInformationTab.error))
    }
    if (auditOpensGenerateCode.error) {
      showAlertLoadDataError(displayError(auditOpensGenerateCode.error))
    }
  }, [
    getActionPlanPersonnelForAuditOpen.error,
    personnelSelect.error,
    auditOpensInformationSave.error,
    dataAuditOpensInformationTab.error,
    auditOpensGenerateCode.error,
  ])
  const onFinish = async (values) => {
    const sendFor = values?.sendFor?.map((items) => {
      return {
        openToType: 1,
        organizationCode: items?.source?.organizationCode,
        personnelCode: items?.source?.personnelCode,
        email: items?.source?.email,
      }
    })

    const sendForCopy = _.filter(sendForCopyMaster, (data) =>
      values?.sendForCopy?.includes(String(data?.personnelCode)),
    ).map((items) => {
      return {
        openToType: 2,
        organizationCode: items?.organizationCode,
        personnelCode: items?.personnelCode,
        email: items?.email,
      }
    })

    let input
    if (typeForm === 'add') {
      input = {
        typeAction: typeForm,
        openId: refCode ? refCode : null,
        lang: localStorage.getItem('lang'),
        openDate: values.openDate,
        projectTypeCode: values.projectTypeCode,
        planCode: values.planCode,
        projectCode: values.projectCode,
        organizationCode: values.organizationCode,
        openFor: values.openFor,
        openSubject: values.openSubject,
        auditOpenOrganizations: [...sendFor, ...sendForCopy],
        openInform: values.openInform,
        openPost: values.openPost,
        openSigner: values.openSigner,
        openReview: values.openReview,
        openCoordinate: values.openCoordinate,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
        files: file.join(),
        idFileDelete: idFileDelete.join(),
        fileDetail: JSON.stringify({
          link: values.link,
          name: values.docName,
        }),
      }
    } else {
      input = {
        typeAction: typeForm,
        openId: refCode ? refCode : null,
        organizationCode: form.getFieldValue('organizationCode'),
        openNumber: openNumber,
        auditOpenOrganizations: [...sendFor, ...sendForCopy],
      }
    }

    auditOpensInformationSaveFn({
      variables: {
        input: encryptInput(input),
      },
      fetchPolicy: 'no-cache',
    })
  }
  return (
    <>
      {(auditOpensInformationSave.loading ||
        personnelSelect.loading ||
        getActionPlanPersonnelForAuditOpen.loading ||
        dataAuditOpensInformationTab.loading ||
        auditOpensGenerateCode.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Form
            form={form}
            autoComplete="off"
            labelAlign="left"
            layout="horizontal"
            name="auditOpensInformation"
            onFinish={onFinish}
            labelCol={{ sm: 24, md: 4 }}
            wrapperCol={{ sm: 24, md: 20 }}
          >
            <Label type="body-header">{t('ข้อมูลเปิดตรวจ')}</Label>
            <Row justify="center">
              <Col md={18}>
                <Form.Item
                  name="openDate"
                  label={<Label type="tab-header-inactive">{t('วันที่หนังสือ')}</Label>}
                  rules={[{ required: true, message: t('กรุณาเลือกวันที่หนังสือ') }]}
                >
                  <Datepicker
                    setValueDateFn={async (data) => {
                      form.setFieldsValue({
                        openDate: await data,
                      })
                    }}
                    onChange={() => {}}
                    disabled={!editable}
                    onClear={() => {
                      form.setFieldsValue({ openDate: null })
                    }}
                    placeholder={t('เลือกวันที่หนังสือ')}
                  />
                </Form.Item>
                <Form.Item label={<Label type="tab-header-inactive">{t('เลขที่หนังสือเปิดตรวจ')}</Label>}>
                  <Row>
                    <Col xl={24}>
                      <Input.Group>
                        <Form.Item name="openNumber" style={{ width: '100%' }}>
                          <InputNew placeholder={t('รันเลขที่หนังสือเปิดตรวจ')} disabled={true} />
                        </Form.Item>

                        <Button
                          style={{ minHeight: '37px', marginLeft: '5px' }}
                          type="primary"
                          htmlType="button"
                          disabled={typeForm === 'edit' ? disabledRunNumber : true}
                          onClick={() => {
                            auditOpensGenerateCodeFn({
                              variables: {
                                input: encryptInput({
                                  openId: refCode,
                                  organizationCode: form.getFieldValue('organizationCode'),
                                }),
                              },
                              fetchPolicy: 'no-cache',
                            })
                          }}
                        >
                          {t('เลขที่หนังสือ')}
                        </Button>
                      </Input.Group>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  initialValue={'10'}
                  name="projectTypeCode"
                  label={<Label type="tab-header-inactive">{t('ประเภทของโครงการ')}</Label>}
                  rules={[{ required: true, message: t('กรุณาเลือกประเภทของโครงการ') }]}
                >
                  <SelectProjectTypes
                    disabled={!editable}
                    filters={{ isActive: 1 }}
                    formname="auditOpensInformation"
                    placeholder={t('เลือกประเภทของโครงการ')}
                    handleChange={(value) => {
                      form.setFieldsValue({
                        projectTypeCode: value ? value.value : null,
                        planCode: null,
                        projectCode: null,
                        organizationName: '',
                        organizationCode: '',
                        sendFor: [],
                      })
                      setProjectTypeCode(value ? value.value : null)
                      setPlanCode(null)
                      setProjectCode(null)
                      if (value) {
                        setErrorField('projectTypeCode', false)
                      } else {
                        setErrorField('projectTypeCode', t('กรุณาเลือกประเภทของโครงการ'))
                      }
                      setErrorField('projectCode', t('กรุณาเลือกโครงการ'))
                      setErrorField('organizationName', t('กรุณาระบุหน่วยที่รับผิดชอบ'))
                      setErrorField('sendFor', t('กรุณาเลือกหน่วยรับตรวจ'))
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="planCode"
                  label={<Label type="tab-header-inactive">{t('แผนการตรวจสอบ')}</Label>}
                  rules={[{ required: true, message: t('กรุณาเลือกแผนการตรวจสอบ') }]}
                >
                  <SelectPlan
                    disabled={!editable}
                    filters={{ isActive: 1 }}
                    formname="auditOpensInformation"
                    placeholder={t('เลือกแผนการตรวจสอบ')}
                    handleChange={(value) => {
                      form.setFieldsValue({
                        planCode: value ? value.value : null,
                        projectCode: null,
                        organizationName: '',
                        organizationCode: '',
                        sendFor: [],
                      })
                      setPlanCode(value ? value.value : null)
                      setProjectCode(null)
                      setErrorField('projectCode', t('กรุณาเลือกโครงการ'))
                      setErrorField('organizationName', t('กรุณาระบุหน่วยที่รับผิดชอบ'))
                      setErrorField('sendFor', t('กรุณาเลือกหน่วยรับตรวจ'))
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('โครงการ')}</Label>}
                  name="projectCode"
                  rules={[{ required: true, message: t('กรุณาเลือกโครงการ') }]}
                >
                  <SelectAuditProjects
                    disabled={!editable}
                    filters={{
                      isActive: 1,
                      projectTypeCode: projectTypeCode,
                      planCode: planCode,
                      hasActionPlan: true,
                    }}
                    formname="auditOpensInformation"
                    placeholder={t('เลือกโครงการ')}
                    handleChange={(value) => {
                      form.setFieldsValue({
                        projectCode: value ? value.value : null,
                        organizationName: value
                          ? displayText(value.source.organizationNameTH, value.source.organizationNameEN)
                          : '',
                        organizationCode: value ? value.source.organizationCode : '',
                        sendFor: [],
                      })
                      setProjectCode(value ? value.value : null)
                      if (value) {
                        setErrorField('projectCode', false)
                      } else {
                        setErrorField('projectCode', t('กรุณาเลือกโครงการ'))
                      }
                      if (
                        !(value ? displayText(value.source.organizationNameTH, value.source.organizationNameEN) : '')
                      ) {
                        setErrorField('organizationName', t('กรุณาระบุหน่วยที่รับผิดชอบ'))
                      }
                      setErrorField('sendFor', t('กรุณาเลือกหน่วยรับตรวจ'))
                    }}
                  />
                </Form.Item>
                <Form.Item name="organizationCode" hidden={true}>
                  <InputNew />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('หน่วยที่รับผิดชอบ')}</Label>}
                  name="organizationName"
                  rules={[{ required: true, message: t('กรุณาระบุหน่วยที่รับผิดชอบ') }]}
                >
                  <InputNew placeholder={t('ระบุหน่วยที่รับผิดชอบ')} disabled={true} />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('เพื่อพิจารณา')}</Label>}
                  name="openFor"
                  rules={[
                    { required: true, message: t('กรุณาระบุเพื่อพิจารณา') },
                    {
                      max: 200,
                      message: t('กรุณาระบุเพื่อพิจารณาน้อยกว่าหรือเท่ากับ 200 ตัวอักษร'),
                    },
                  ]}
                >
                  <InputNew maxLength={200} placeholder={t('ระบุเพื่อพิจารณา')} disabled={!editable} />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('เรื่อง')}</Label>}
                  name="openSubject"
                  rules={[
                    { required: true, message: t('กรุณาระบุเรื่อง') },
                    {
                      max: 200,
                      message: t('กรุณาระบุเรื่องน้อยกว่าหรือเท่ากับ 200 ตัวอักษร'),
                    },
                  ]}
                >
                  <InputNew maxLength={200} placeholder={t('ระบุเรื่อง')} disabled={!editable} />
                </Form.Item>

                <Form.Item
                  label={<Label type="tab-header-inactive">{t('เรียน')}</Label>}
                  name="sendFor"
                  rules={[{ required: true, message: t('กรุณาเลือกหน่วยรับตรวจ') }]}
                >
                  <SelectActionPlanOrganizations
                    // disabled={!editable} // ที่ comment ไว้ เพราะตอนแรกปรับแก้ไว้ถ้ามีเลขที่หนังสือเปิดตรวจ จะไม่สามารถให้แก้ไขได้ แต่ตอนนีเขาอยากให้ปิดเพื่อแก้ได้ก่อน
                    multiple={true}
                    filters={{ isActive: 1 }}
                    formname="auditOpensInformation"
                    placeholder={t('เลือกหน่วยรับตรวจ')}
                    handleChange={(value) => {
                      form.setFieldsValue({ sendFor: value })
                      if (value.length) {
                        setErrorField('sendFor', false)
                      } else {
                        setErrorField('sendFor', t('กรุณาเลือกหน่วยรับตรวจ'))
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('สำเนาเรียน')}</Label>}
                  name="sendForCopy"
                  rules={[{ required: true, message: t('กรุณาเลือกสำเนาเรียน') }]}
                >
                  <SelectPersonnels
                    // disabled={!editable} // ที่ comment ไว้ เพราะตอนแรกปรับแก้ไว้ถ้ามีเลขที่หนังสือเปิดตรวจ จะไม่สามารถให้แก้ไขได้ แต่ตอนนีเขาอยากให้ปิดเพื่อแก้ได้ก่อน
                    multiple={true}
                    filters={{ personnaloperationCode: '10' }}
                    formname="auditOpensInformation"
                    placeholder={t('เลือกสำเนาเรียน')}
                    handleChange={(value) => {
                      form.setFieldsValue({ sendForCopy: value })
                      if (value.length) {
                        setSendForCopy(value.map((item) => "'" + item + "'").join())
                        setErrorField('sendForCopy', false)
                      } else {
                        setSendForCopy('noselect')
                        setErrorField('sendForCopy', t('กรุณาเลือกสำเนาเรียน'))
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('รายละเอียดการแจ้ง')}</Label>}
                  name="openInform"
                  rules={[{ required: true, message: t('กรุณาระบุรายละเอียดการแจ้ง') }]}
                >
                  <TextArea placeholder={t('ระบุรายละเอียดการแจ้ง')} disabled={!editable} />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</Label>}
                  name="actionPlanPersonnel"
                  rules={[{ required: true, message: t('กรุณาระบุผู้รับผิดชอบ') }]}
                >
                  <SelectActionPlanPersonnel
                    multiple={true}
                    filters={{ projectCode: projectCode }}
                    formname="auditOpensInformation"
                    placeholder={t('ระบุผู้รับผิดชอบ')}
                    disabled={true}
                    handleChange={(value) => {
                      form.setFieldsValue({ actionPlanPersonnel: value })
                      if (value.length) {
                        setErrorField('actionPlanPersonnel', false)
                      } else {
                        setErrorField('actionPlanPersonnel', t('กรุณาระบุผู้รับผิดชอบ'))
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('คำลงท้าย')}</Label>}
                  name="openPost"
                  rules={[{ required: true, message: t('กรุณาระบุคำลงท้าย') }]}
                >
                  <TextArea placeholder={t('ระบุคำลงท้าย')} disabled={!editable} />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('ผู้ลงนาม')}</Label>}
                  name="openSigner"
                  rules={[{ required: true, message: t('กรุณาเลือกผู้ลงนาม') }]}
                >
                  <SelectPersonnels
                    disabled={!editable}
                    filters={{ personnaloperationCode: '10', levelCode: 12 }}
                    formname="auditOpensInformation"
                    placeholder={t('เลือกผู้ลงนาม')}
                    handleChange={(value) => {
                      form.setFieldsValue({ openSigner: value ? value.value : null })
                      if (value) {
                        setErrorField('openSigner', false)
                      } else {
                        setErrorField('openSigner', t('กรุณาเลือกผู้ลงนาม'))
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('ผู้สอบทาน')}</Label>}
                  name="openReview"
                  rules={[{ required: true, message: t('กรุณาเลือกผู้สอบทาน') }]}
                >
                  <SelectPersonnels
                    disabled={!editable}
                    filters={{ personnaloperationCode: '10', levelCodeWhereIn: "'08','09','10','11'" }}
                    formname="auditOpensInformation"
                    placeholder={t('เลือกผู้สอบทาน')}
                    handleChange={(value) => {
                      form.setFieldsValue({ openReview: value ? value.value : null })
                      if (value) {
                        setErrorField('openReview', false)
                      } else {
                        setErrorField('openReview', t('กรุณาเลือกผู้สอบทาน'))
                      }
                    }}
                  />
                </Form.Item>
                <FormDisplayFlex>
                  <Form.Item
                    style={{ marginBottom: 0, width: '100%', display: 'flex' }}
                    labelCol={{ sm: 0, md: 0 }}
                    wrapperCol={{ sm: 24, md: 24 }}
                  >
                    <Form.Item
                      label={<Label type="tab-header-inactive">{t('ผู้ประสานงาน')}</Label>}
                      style={{ width: '50%', marginRight: '2%' }}
                      name="openCoordinate"
                      rules={[{ required: true, message: t('กรุณาเลือกผู้ประสานงาน') }]}
                      labelCol={{ sm: 24, md: 8 }}
                      wrapperCol={{ sm: 24, md: 16 }}
                    >
                      <SelectPersonnels
                        disabled={!editable}
                        filters={{
                          personnaloperationCode: '10',
                          levelCodeWhereIn: "'05','06','07','08','09','10','11'",
                        }}
                        formname="auditOpensInformation"
                        placeholder={t('เลือกผู้ประสานงาน')}
                        handleChange={(value) => {
                          form.setFieldsValue({
                            openCoordinate: value ? value.value : null,
                            openCoordinateTel: value
                              ? decryptData(value.source.mobile)
                                ? decryptData(value.source.mobile)
                                : value.source.telephone
                              : '',
                          })
                          if (value) {
                            setErrorField('openCoordinate', false)
                          } else {
                            setErrorField('openCoordinate', t('กรุณาเลือกผู้ประสานงาน'))
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<Label type="tab-header-inactive">{t('เบอรติดต่อ')}</Label>}
                      style={{ width: '48%' }}
                      labelCol={{ sm: 24, md: 8 }}
                      wrapperCol={{ sm: 24, md: 16 }}
                      name="openCoordinateTel"
                    >
                      <InputNew placeholder={t('ระบุเบอรติดต่อ')} disabled={true} />
                    </Form.Item>
                  </Form.Item>
                </FormDisplayFlex>
                <FormDisplayFlex>
                  <Form.Item
                    style={{ marginBottom: 0, width: '100%', display: 'flex' }}
                    labelCol={{ sm: 0, md: 0 }}
                    wrapperCol={{ sm: 24, md: 24 }}
                  >
                    <Form.Item
                      label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}
                      style={{ width: '50%', marginRight: '2%' }}
                      name="dateStart"
                      rules={[{ required: true, message: t('กรุณาเลือกวันที่เริ่มต้น') }]}
                      labelCol={{ sm: 24, md: 8 }}
                      wrapperCol={{ sm: 24, md: 16 }}
                    >
                      <Datepicker
                        setValueDateFn={async (data) => {
                          form.setFieldsValue({ dateStart: await data })
                          validDate()
                        }}
                        onChange={() => {}}
                        disabled={!editable}
                        onClear={() => {
                          form.setFieldsValue({ dateStart: null })
                        }}
                        placeholder={t('เลือกวันที่เริ่มต้น')}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}
                      style={{ width: '48%' }}
                      name="dateEnd"
                      rules={[{ required: true, message: t('กรุณาเลือกวันที่สิ้นสุด') }]}
                      labelCol={{ sm: 24, md: 8 }}
                      wrapperCol={{ sm: 24, md: 16 }}
                    >
                      <Datepicker
                        setValueDateFn={async (data) => {
                          form.setFieldsValue({ dateEnd: await data })
                          validDate()
                        }}
                        onChange={() => {}}
                        disabled={!editable}
                        onClear={() => {
                          form.setFieldsValue({ dateEnd: null })
                        }}
                        placeholder={t('เลือกวันที่สิ้นสุด')}
                      />
                    </Form.Item>
                  </Form.Item>
                </FormDisplayFlex>
              </Col>
            </Row>
            <Label type="body-header">{t('แนบเอกสาร')}</Label>
            <Row>
              <Col md={24}>
                <TableFile>
                  <Table
                    pagination={false}
                    columns={[
                      {
                        title: <div style={{ textAlign: 'center' }}>{t('ชื่อเอกสาร')}</div>,
                        dataIndex: 'docName',
                        width: '30%',
                      },
                      {
                        title: <div style={{ textAlign: 'center' }}>{t('ลิงค์')}</div>,
                        dataIndex: 'link',
                        width: '30%',
                      },
                      {
                        title: <div style={{ textAlign: 'center' }}>{t('แนบเอกสาร')}</div>,
                        dataIndex: 'file',
                        width: '30%',
                      },
                      {
                        title: '',
                        dataIndex: 'action',
                        width: '10%',
                      },
                    ]}
                    dataSource={[
                      {
                        key: 1,
                        docName: (
                          <Form.Item name="docName">
                            <InputNew placeholder={t('ระบุชื่อเอกสาร')} disabled={!editable} />
                          </Form.Item>
                        ),
                        link: (
                          <>
                            <Form.Item
                              name="link"
                              rules={[
                                {
                                  type: 'url',
                                  message: t('กรุณาระบุลิงค์เอกสารให้ถูกต้อง'),
                                },
                              ]}
                              hidden={!editable}
                            >
                              <InputNew placeholder={t('ระบุลิงค์')} />
                            </Form.Item>
                            <a
                              href={form.getFieldValue('link')}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: '#2b4990', display: typeForm !== 'view' ? 'none' : 'auto' }}
                            >
                              <u>{form.getFieldValue('link')}</u>
                            </a>
                          </>
                        ),
                        file: (
                          <Upload
                            setIdFile={setFile}
                            idFile={file}
                            actionType={typeForm === 'add' ? 'create' : typeForm === 'edit' ? 'view' : typeForm}
                            idFileDelete={idFileDelete}
                            defaults={idFileDefault}
                            setIdFileDelete={setIdFileDelete}
                            typeFile="file"
                            disabled={!editable}
                          />
                        ),
                        action: (
                          <ButtonNew
                            disabled={!editable}
                            onClick={() => {
                              form.setFieldsValue({
                                docName: '',
                                link: '',
                              })
                            }}
                            type="clearUseInTable"
                          />
                        ),
                      },
                    ]}
                  />
                </TableFile>
              </Col>
            </Row>
            <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
              {/* {(typeForm === 'add' || (typeForm === 'edit' && !openNumber)) && ( // ที่ comment ไว้ เพราะตอนแรกปรับแก้ไว้ถ้ามีเลขที่หนังสือเปิดตรวจ จะไม่สามารถให้แก้ไขได้ แต่ตอนนีเขาอยากให้ปิดเพื่อแก้ได้ก่อน*/}
              {(typeForm === 'add' || typeForm === 'edit') && (
                <Col>
                  <Button
                    type="primary"
                    roles={{ type: typeForm, menu: props.menu }}
                    onClick={() => {
                      showConfirm()
                    }}
                    htmlType="button"
                  >
                    {t('บันทึก')}
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  type="cancel"
                  onClick={() => {
                    navigate('/auditoperations/auditopens')
                  }}
                >
                  {t('ยกเลิก')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default FormAuditOpensInformation
