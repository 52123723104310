import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
// import WithinTheTeamIndex from './withintheteam/WithinTheTeamIndex.jsx'
// import OverviewIndex from './overview/OverviewIndex'
// import JobInResponsibleIndex from './jobinresponsible/JobInResponsibleIndex.jsx'
import OverviewIssueIndex from './overviewissue/OverviewIssueIndex.jsx'
// import AuditorWorkIndex from './auditorwork/AuditorWorkIndex.jsx'
import ReceivableauditIndex from './receivableaudit/ReceivableauditIndex'
import OverviewFollowUpIndex from './overviewfollowup/OverviewFollowUpIndex.jsx'
import StatusCauseAndIssueDetectedIndex from './statuscauseandissuedetected/StatusCauseAndIssueDetectedIndex'
import RiskAuditUniverseIndex from './riskaudituniverse/RiskAuditUniverseIndex.jsx'
import OverviewSlaProcessIndex from './overviewslaprocess/OverviewSlaProcessIndex.jsx'
import InspectorWorkIndex from './inspectorwork/InspectorWorkIndex.jsx'

/**
 * @function Router
 * @description
 * Module: Dashboard
 * โมดูล: แผงควบคุม
 * @returns Routes
 */

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard/overviewfollowup" />} />
      {/* <Route path="/overview">
        <Route path="" element={<OverviewIndex menu={'DB01'} />} />
      </Route> */}
      <Route path="/overviewfollowup">
        <Route path="" element={<OverviewFollowUpIndex menu={'DB01'} />} />
      </Route>
      {/* <Route path="/withintheteam">
        <Route path="" element={<WithinTheTeamIndex menu={'DB02'} />} />
      </Route> */}
      <Route path="/statuscauseandissuedetected">
        <Route path="" element={<StatusCauseAndIssueDetectedIndex menu={'DB02'} />} />
      </Route>
      <Route path="/riskaudituniverse">
        <Route path="" element={<RiskAuditUniverseIndex menu={'DB03'} />} />
      </Route>
      {/* <Route path="/jobinresponsible">
        <Route path="" element={<JobInResponsibleIndex menu={'DB03'} />} />
      </Route> */}
      <Route path="/overviewissue">
        <Route path="" element={<OverviewIssueIndex menu={'DB04'} />} />
      </Route>
      {/* <Route path="/auditorwork">
        <Route path="" element={<AuditorWorkIndex menu={'DB05'} />} />
      </Route> */}
      <Route path="/auditorwork">
        <Route path="" element={<OverviewSlaProcessIndex menu={'DB05'} />} />
      </Route>
      <Route path="/receivableaudit">
        <Route path="" element={<ReceivableauditIndex menu={'DB06'} />} />
      </Route>
      <Route path="/inspectorwork">
        <Route path="" element={<InspectorWorkIndex menu={'DB07'} />} />
      </Route>
    </Routes>
  )
}
export default Router
