import { Card, Col, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Datatable } from '../../../../components/datatable/Datatable'

import { Columns } from './utils/Constants'
import { DataTableStyled } from './css/DataTableStyled'

import { handleSort } from '../../../../utilitys/pagination'
import { useGetPagination } from './hooks/useGetPagination'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { displayText } from '../../../../utilitys/helper'
import { dateDisplay } from '../../../../utilitys/dateHelper'

const Responsive = {
  md: 24,
  lg: 24,
}

const OverviewSlaProcessList = (props) => {
  const { dataFilter } = props

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])

  const inputOptions = useMemo(() => {
    return { filters: { ...dataFilter }, page, limit, sort }
  }, [dataFilter, page, limit, sort])

  //========================== API PAGINATION ======================================
  const [dataList, listLoading] = useGetPagination(inputOptions)

  useEffect(() => {
    if (dataList) {
      const { result, count } = dataList

      const newData = result.map((dt, idx) => {
        return {
          ...dt,
          key: idx,
          projectName: dt.projectCode + ' : ' + displayText(dt.projectNameTH, dt.projectNameEN),
          projectStatusName: displayText(dt.projectStatusNameTH, dt.projectStatusNameEN),
          operationStatusName: displayText(dt.operationStatusNameTH, dt.operationStatusNameEN),
          openDate: dateDisplay(dt.openDate),
          createdAt: dateDisplay(dt.createdAt),
          dateExit: dateDisplay(dt.dateExit),
          meetingDate: dateDisplay(dt.meetingDate),
          dateSendReport: dateDisplay(dt.dateSendReport),
        }
      })
      setDataLists(newData)
      setCount(count)
    }
  }, [dataList])

  return (
    <DataTableStyled>
      <Card className="">
        <Row gutter={[16, 8]}>
          <Col {...Responsive}>
            {listLoading && <SpinnersSmaillNew />}
            <Datatable
              columns={Columns({})}
              data={dataLists}
              total={count}
              searchCustom={false}
              paginationCustom={true}
              scroll={{ x: 2000 }}
              enableScroll={{ x: true, y: false }}
              pageSize={limit}
              page={page}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              showSizeChanger={true}
              handleTableChange={(e) => {
                const reNewField = [
                  { projectName: 'projectCode' },
                  { projectStatusName: 'projectStatusCode' },
                  { operationStatusName: 'operationStatusCode' },
                  { openDate: 'openDate' },
                  { createdAt: 'createdAt' },
                  { dateExit: 'dateExit' },
                  { meetingDate: 'meetingDate' },
                  { dateSendReport: 'dateSendReport' },
                ]

                setSort(handleSort(e?.sorter, reNewField))
              }}
            />
          </Col>
        </Row>
      </Card>
    </DataTableStyled>
  )
}

export default OverviewSlaProcessList
