import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Space, Input } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew } from '../../../components/input/Input'
import { PERSONNELS_SAVE } from './graphql/Mutation'
import { GET_PERSONNEL, GET_USER_LDAP } from './graphql/Query'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { LabelNew } from '../../../components/label/Label'
import { CardNew } from '../../../components/card/Card'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
// import { Datepicker } from '../../../components/datepicker/Datepicker'
import { UploadProfileNew } from '../../../components/uploadprofile/UploadProfile.jsx'
import SelectGenders from '../../../components/inputfromapi/selectgenders/SelectGenders'
import SelectNamePrefixes from '../../../components/inputfromapi/selectnameprefixes/SelectNamePrefixes'
import SelectPersonnelType from '../../../components/inputfromapi/selectpersonneltype/SelectPersonnelType'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization.jsx'
import SelectPersonnelPositions from '../../../components/inputfromapi/selectpersonnelpositions/SelectPersonnelPositions.jsx'
import SelectNewPersonnelStatuses from '../../../components/inputfromapi/selectnewpersonnelstatuses/SelectNewPersonnelStatuses.jsx'
import SelectPersonnelOperation from '../../../components/inputfromapi/selectpersonneloperation/SelectPersonnelOperation'
import SelectPersonnelLevels from '../../../components/inputfromapi/selectpersonnellevels/SelectPersonnelLevels'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { MaskEmail, MaskPhone, MaskNameText, regexEng, regexThai, displayText } from '../../../utilitys/helper'
import { Datepicker } from '../../../components/datepicker/Datepicker.jsx'
// import { dateStringToPicker } from '../../../utilitys/dateHelper'
import { displayError } from '../../../utilitys/helper'

const PersonnelForm = (props) => {
  const {
    menu,
    setIsUpdate,
    setUpdateData,
    updateData,
    setRefCodeNew,
    formType,
    setFormType,
    contentNameTypeCreate,
    setContentNameTypeCreate
  } = props
  const formname = 'personnelForm'
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  const [saveData, { loading: loadingSave, data: dataSave, error: errorSave }] = useMutation(PERSONNELS_SAVE)
  const [decrypt, setDecrypt] = useState()
  const [getPersonnelFn, { data: dataPersonnel, error: errorData, loading: loadingData }] = useLazyQuery(GET_PERSONNEL)
  const [getUserLdapFn, { data: userLdap, error: errorUserLdap, loading: loadingUserLdap }] =
    useLazyQuery(GET_USER_LDAP)
  const [fileProfile, setFileProfile] = useState([])
  const [chFileProfile, setChFileProfile] = useState(false)
  const [typeAction, setTypeAction] = useState()
  const [checkUserLdap, setCheckUserLdap] = useState(false)
  const [isPersonnelCode, setIsPersonnelCode] = useState(null)

  const [contentName, setContentName] = useState()

  const getData = (personnelCode) => {
    getPersonnelFn({ variables: { input: encryptInput({ personnelCode: personnelCode }) } })
  }

  useEffect(() => {
    // เมื่อ updateData มีค่า มันจะไม่ยิง api get
    if ((formType === 'edit' || formType === 'view') && state.refCode) {
      getData(state.refCode)
    }
  }, [state.refCode])

  useEffect(() => {
    if (checkUserLdap && isPersonnelCode) {
      getUserLdapFn({
        variables: { input: encryptInput({ initials: isPersonnelCode }) },
        fetchPolicy: 'no-cache'
      })
    }
  }, [checkUserLdap, isPersonnelCode])

  useEffect(() => {
    if (userLdap?.getUserLdap) {
      const user = userLdap?.getUserLdap?.users[0]

      const fullNameTH = user?.description ? user?.description.split(' ') : null
      const personnaloperationCode =
        user?.userAccountControl === '512' ? '10' : user?.userAccountControl === '514' ? '30' : null

      form.setFieldsValue({
        firstNameTH: fullNameTH ? fullNameTH[0] : null,
        lastNameTH: fullNameTH ? fullNameTH[1] : null,
        firstNameEN: user?.givenName ? user?.givenName : null,
        lastNameEN: user?.sn ? user?.sn : null,
        personnaloperationCode: personnaloperationCode,
        email: user?.mail,
        levelCode: user?.physicalDeliveryOfficeName ? user?.physicalDeliveryOfficeName : null,
        positionCode: user?.title ? user?.title : null,
        organizationCode: user?.department ? user?.department : null
      })
      setCheckUserLdap(false)
    }
  }, [userLdap])

  useEffect(() => {
    if (fileProfile.length && !chFileProfile) {
      const newValue = form.getFieldValue()
      setUpdateData({ ...newValue, fileProfile: fileProfile })
      setIsUpdate(true)
    }
    setChFileProfile(false)
  }, [fileProfile])

  useEffect(() => {
    // useEffect ตัวนี้ เอาไว้ ดักจับ updateData เพื่อมีการเปลี่ยนแปลงให้ การ set ข้อมูล ลง form
    if (updateData) {
      form.setFieldsValue({
        personnelCode: updateData.personnelCode,
        personnalTypeCode: updateData.personnalTypeCode,
        organizationCode: updateData.organizationCode,
        positionCode: updateData.positionCode,
        levelCode: updateData.levelCode,
        personnaloperationCode: updateData.personnaloperationCode,
        dateOfLeave: updateData.dateOfLeave || null,
        newPersonnelStatusCode: updateData.newPersonnelStatusCode,
        dateOfStartWorking: updateData.dateOfStartWorking || null,
        namePrefixCode: updateData.namePrefixCode,
        firstNameTH: updateData.firstNameTH,
        lastNameTH: updateData.lastNameTH,
        firstNameEN: updateData.firstNameEN,
        lastNameEN: updateData.lastNameEN,
        genderCode: updateData.genderCode,
        dateOfBirth: updateData.dateOfBirth ? updateData.dateOfBirth : null,
        mobile: updateData.mobile,
        emergency: updateData.emergency,
        lineId: updateData.lineId,
        email: updateData.email,
        telephone: updateData.telephone,
        responsibility: updateData.responsibility
      })
      setFileProfile(updateData.fileProfile)
    }
  }, [updateData])

  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {}
    })
  }
  const onSubmit = async (values) => {
    let dataInput = {
      personnelCode: values.personnelCode || '',
      personnalTypeCode: values.personnalTypeCode || '',
      organizationCode: values.organizationCode || '',
      positionCode: values.positionCode || '',
      levelCode: values.levelCode || '',
      personnaloperationCode: values.personnaloperationCode || '',
      dateOfLeave: values.dateOfLeave || '',
      newPersonnelStatusCode: values.newPersonnelStatusCode || '',
      dateOfStartWorking: values.dateOfStartWorking || '',
      namePrefixCode: values.namePrefixCode || '',
      firstNameTH: values.firstNameTH || '',
      lastNameTH: values.lastNameTH || '',
      firstNameEN: values.firstNameEN || '',
      lastNameEN: values.lastNameEN || '',
      genderCode: values.genderCode || 'z',
      dateOfBirth: values.dateOfBirth || '',
      mobile: values.mobile || '',
      emergency: values.emergency || '',
      lineId: values.lineId || '',
      email: values.email || '',
      telephone: values.telephone || '',
      responsibility: values.responsibility ? values.responsibility : 0,
      profilePicture: fileProfile
    }

    if (formType === 'add') {
      dataInput['action'] = 'add'
    } else if (formType === 'edit') {
      dataInput['action'] = 'edit'
    }

    saveData({ variables: { input: encryptInput(dataInput) } })
  }

  useEffect(() => {
    if (errorData && errorData.message) {
      showAlertError(t(displayError(errorData)))
    }
  }, [errorData])

  useEffect(() => {
    if (errorUserLdap) {
      showAlertError(t(displayError(errorUserLdap)))
    }
  }, [errorUserLdap])

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: text,
      onOk() {}
    })
  }

  useEffect(() => {
    if (dataSave) {
      state.refCode = dataSave.personnelsSave.personnelCode
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          getData(dataSave.personnelsSave.personnelCode)
          setTypeAction('edit')
          setFormType('edit')
        }
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (errorSave && errorSave.message) {
      showAlertError(t(displayError(errorSave)))
    }
  }, [errorSave])

  useEffect(() => {
    // Get value From your API
    // Set Form value on formType = UPDATE
    if (typeAction === 'edit' || formType === 'edit' || formType === 'view') {
      if (dataPersonnel) {
        setContentName({
          prefix: displayText(dataPersonnel.getPersonnel.namePrefixTH, dataPersonnel.getPersonnel.namePrefixEN),
          fName: displayText(dataPersonnel.getPersonnel.firstNameTH, dataPersonnel.getPersonnel.firstNameEN),
          lName: displayText(dataPersonnel.getPersonnel.lastNameTH, dataPersonnel.getPersonnel.lastNameEN)
        })

        setRefCodeNew(dataPersonnel.getPersonnel.personnelCode)
        setFileProfile([dataPersonnel.getPersonnel.profilePicture || 'null'])
        setChFileProfile(true)
        form.setFieldsValue({
          personnelCode: dataPersonnel.getPersonnel.personnelCode,
          personnalTypeCode: dataPersonnel.getPersonnel.personnalTypeCode,
          organizationCode: dataPersonnel.getPersonnel.organizationCode,
          positionCode: dataPersonnel.getPersonnel.positionCode,
          levelCode: dataPersonnel.getPersonnel.levelCode,
          personnaloperationCode: dataPersonnel.getPersonnel.personnaloperationCode,
          dateOfLeave: dataPersonnel.getPersonnel.dateOfLeave,
          newPersonnelStatusCode: dataPersonnel.getPersonnel.newPersonnelStatusCode,
          dateOfStartWorking: dataPersonnel.getPersonnel.dateOfStartWorking,
          namePrefixCode: dataPersonnel.getPersonnel.namePrefixCode,
          firstNameTH:
            formType === 'view'
              ? MaskNameText(dataPersonnel.getPersonnel.firstNameTH)
              : dataPersonnel.getPersonnel.firstNameTH,
          lastNameTH:
            formType === 'view'
              ? MaskNameText(dataPersonnel.getPersonnel.lastNameTH)
              : dataPersonnel.getPersonnel.lastNameTH,
          firstNameEN:
            formType === 'view'
              ? MaskNameText(dataPersonnel.getPersonnel.firstNameEN)
              : dataPersonnel.getPersonnel.firstNameEN,
          lastNameEN:
            formType === 'view'
              ? MaskNameText(dataPersonnel.getPersonnel.lastNameEN)
              : dataPersonnel.getPersonnel.lastNameEN,
          genderCode: dataPersonnel.getPersonnel.genderCode,
          dateOfBirth: moment(new Date(dataPersonnel.getPersonnel.dateOfBirth)).format('YYYY-MM-DD'),
          mobile: decrypt
            ? decrypt.mobile === 'undefined'
              ? ''
              : formType === 'view'
              ? MaskPhone(decrypt.mobile)
              : decrypt.mobile
            : '',
          emergency: decrypt
            ? decrypt.emergency === 'undefined'
              ? ''
              : formType === 'view'
              ? MaskPhone(decrypt.emergency)
              : decrypt.emergency
            : '',
          lineId: decrypt ? (decrypt.lineId === 'undefined' ? '' : decrypt.lineId) : '',
          email: decrypt
            ? decrypt.email === 'undefined'
              ? ''
              : formType === 'view'
              ? MaskEmail(decrypt.email)
              : decrypt.email
            : '',
          telephone:
            formType === 'view'
              ? MaskPhone(dataPersonnel.getPersonnel.telephone)
              : dataPersonnel.getPersonnel.telephone,
          responsibility: dataPersonnel.getPersonnel.responsibility
            ? parseInt(dataPersonnel.getPersonnel.responsibility)
            : 0
        })
      }
    } else {
      // Set Form value on formType = CREATE
      form.resetFields()
    }

    if (dataPersonnel) {
      if (formType === 'add') {
        setContentNameTypeCreate({
          prefix: dataPersonnel.getPersonnel.namePrefixTH,
          fName: dataPersonnel.getPersonnel.firstNameTH,
          lName: dataPersonnel.getPersonnel.lastNameTH
        })
      }
    }
  }, [dataPersonnel, decrypt])

  useEffect(() => {
    if (dataPersonnel) {
      setDecrypt({
        mobile: decryptData(dataPersonnel.getPersonnel.mobile),
        emergency: decryptData(dataPersonnel.getPersonnel.emergency),
        lineId: decryptData(dataPersonnel.getPersonnel.lineId),
        email: decryptData(dataPersonnel.getPersonnel.email)
      })
    }
  }, [dataPersonnel])

  // onFinish
  const onFinish = async (values) => {
    fnConfirm(values)
  }

  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'datePicker') setErrorField('datePickerMockUp', message)
    })
  }
  // onValuesChange
  const onValuesChange = (part, allValues) => {
    if (part) {
      // setUpdateData(allValues)
      const newValue = { ...allValues, fileProfile: fileProfile }
      setUpdateData(newValue)
      setIsUpdate(true)
    }
    // setFormType(formType) // CREATE or UPDATE *** intitial value = CREATE
  }

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false // Message errors OR remove fields errors
      }
    ])
  }

  // แสดงชื่อที่บน form
  const renderName = () => {
    let text = ''

    if (contentName) {
      if (formType === 'edit') {
        text = contentName.prefix + ' ' + contentName.fName + ' ' + contentName.lName
      } else if (formType === 'view') {
        text = contentName.prefix + ' ' + MaskNameText(contentName.fName) + ' ' + MaskNameText(contentName.lName)
      }
    } else if (contentNameTypeCreate) {
      text = contentNameTypeCreate.prefix + ' ' + contentNameTypeCreate.fName + ' ' + contentNameTypeCreate.lName
    }
    return text
  }

  return (
    <>
      {(loadingUserLdap || loadingSave || loadingData) && <SpinnersNew />}
      <Row style={{ marginTop: 24 }}>
        {/* {contentName && (formType === 'edit' || formType === 'view')
          ? contentName.prefix + ' ' + contentName.fName + ' ' + contentName.lName
          : contentNameTypeCreate
          ? contentNameTypeCreate.prefix + ' ' + contentNameTypeCreate.fName + ' ' + contentNameTypeCreate.lName
          : ''} */}
        {renderName()}
        <Col md={24} lg={24}>
          <CardNew topic={<LabelNew type="sub-header-primary">{t('ข้อมูลอัตรากำลัง')}</LabelNew>}>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              autoComplete="off"
              labelAlign="left"
              labelCol={{
                sm: { span: 9, offset: 0 },
                md: { span: 9, offset: 1 },
                xl: { span: 9, offset: 1 }
              }}
              wrapperCol={{ sm: 24, md: 24, xl: 10, align: 'left' }}
              layout="horizontal"
              name={formname}
            >
              <Row>
                <Col md={12}>
                  <Form.Item label={t('รหัสพนักงาน')}>
                    <Input.Group>
                      <Form.Item
                        name="personnelCode"
                        style={{ width: '100%', marginBottom: '0px' }}
                        rules={[
                          { required: true, message: t('กรุณาระบุรหัสพนักงาน') },
                          { max: 10, message: t('กรุณาระบุรหัสแผนงานน้อยกว่าหรือเท่ากับ 10 ตัวอักษร') }
                        ]}
                      >
                        <InputNew
                          disabled={formType === 'edit' || formType === 'view' ? true : false}
                          maxLength={10}
                          onChange={(e) => setIsPersonnelCode(e?.target?.value)}
                        />
                      </Form.Item>
                      {formType === 'add' && (
                        <ButtonNew
                          style={{ minHeight: '37px', marginLeft: '5px' }}
                          type="primary"
                          roles={{ type: 'add', menu: menu }}
                          action={formType}
                          disabled={formType === 'edit' || formType === 'view' ? true : false}
                          onClick={() => isPersonnelCode && setCheckUserLdap(true)}
                        >
                          {t('ตรวจสอบ')}
                        </ButtonNew>
                      )}
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    name="personnalTypeCode"
                    label={t('ประเภทบุคลากร')}
                    rules={[{ required: true, message: t('กรุณาระบุประเภทบุคลากร') }]}
                  >
                    <SelectPersonnelType
                      formname={'personnelForm'}
                      placeholder={t('เลือกประเภทบุคลากร')}
                      disabled={formType === 'view' ? true : false}
                      handleChange={(e) => form.setFieldsValue({ personnalTypeCode: e?.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="organizationCode"
                    label={t('หน่วยงาน')}
                    rules={[{ required: true, message: t('กรุณาระบุหน่วยงาน') }]}
                  >
                    <SelectOrganization
                      placeholder={t('เลือกหน่วยงาน')}
                      formname="personnelForm"
                      disabled={formType === 'view' ? true : false}
                      handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="positionCode"
                    label={t('ตำแหน่งงาน')}
                    rules={[{ required: true, message: t('กรุณาระบุตำแหน่งงาน') }]}
                  >
                    <SelectPersonnelPositions
                      placeholder={t('เลือกตำแหน่งงาน')}
                      formname="personnelForm"
                      disabled={formType === 'view' ? true : false}
                      handleChange={(e) => form.setFieldsValue({ positionCode: e?.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="levelCode"
                    label={t('ระดับบุคลากร')}
                    rules={[{ required: true, message: t('กรุณาระบุระดับบุคลากร') }]}
                  >
                    <SelectPersonnelLevels
                      formname={'personnelForm'}
                      placeholder={t('เลือกระดับบุคลากร')}
                      isfilterscreen={'true'}
                      disabled={formType === 'view' ? true : false}
                      handleChange={(e) => form.setFieldsValue({ levelCode: e?.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="personnaloperationCode"
                    label={t('สถานะการปฏิบัติงาน')}
                    rules={[{ required: true, message: t('กรุณาระบุสถานะปฏิบัติงาน') }]}
                  >
                    <SelectPersonnelOperation
                      formname={'personnelForm'}
                      placeholder={t('เลือกสถานะปฏิบัติงาน')}
                      isfilterscreen={'true'}
                      disabled={formType === 'view' ? true : false}
                      handleChange={(e) => form.setFieldsValue({ personnaloperationCode: e?.value })}
                    />
                  </Form.Item>
                  {/* โชว์ในกรณีลาออก */}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.personnaloperationCode !== currentValues.personnaloperationCode
                    }
                  >
                    {({ getFieldValue }) => {
                      // 30 = ลาออก, 40 = ย้ายออก, 50 = เกษียร
                      const isLeave = ['30', '40', '50'].includes(getFieldValue('personnaloperationCode'))
                      if (isLeave) {
                        return (
                          <Form.Item
                            name="dateOfLeave"
                            label={t('วันที่ออกจากงาน')}
                            rules={[
                              {
                                required: true,
                                message: t('กรุณาระบุวันที่ออกจากงาน')
                              }
                            ]}
                          >
                            <Datepicker
                              disabled={formType === 'view'}
                              autoComplete="off"
                              setValueDateFn={async (date) => {
                                form.setFieldsValue({ dateOfLeave: await date })
                              }}
                              onClear={() => {
                                form.setFieldsValue({ dateOfLeave: null })
                              }}
                              placeholder={t('DD/MM/YYYY')}
                            />
                          </Form.Item>
                        )
                      }

                      return
                    }}
                  </Form.Item>
                  <Form.Item
                    name="newPersonnelStatusCode"
                    label={t('สถานะภาพการเข้าทำงานใหม่')}
                    rules={[{ required: true, message: t('กรุณาระบุสถานะภาพการเข้าทำงานใหม่') }]}
                  >
                    <SelectNewPersonnelStatuses
                      formname={'personnelForm'}
                      placeholder={t('เลือกสถานะภาพการเข้าทำงานใหม่')}
                      isfilterscreen={'true'}
                      disabled={formType === 'view' ? true : false}
                      handleChange={(e) => form.setFieldsValue({ newPersonnelStatusCode: e?.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="dateOfStartWorking"
                    label={t('วันที่เริ่มงาน')}
                    rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มงาน') }]}
                  >
                    <Datepicker
                      disabled={formType === 'view'}
                      autoComplete="off"
                      setValueDateFn={async (date) => {
                        form.setFieldsValue({ dateOfStartWorking: await date })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ dateOfStartWorking: null })
                      }}
                      placeholder={t('DD/MM/YYYY')}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} align="center">
                  <Form.Item>
                    <UploadProfileNew setIdFile={setFileProfile} idFile={fileProfile} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <LabelNew type="sub-header-primary">{t('ข้อมูลพื้นฐานบุคลากร')}</LabelNew>
              </Row>
              <Row style={{ marginTop: 24 }}>
                <Col md={12}>
                  <Form.Item
                    name="namePrefixCode"
                    label={t('คำนำหน้า')}
                    rules={[{ required: true, message: t('กรุณาระบุคำนำหน้า') }]}
                  >
                    <SelectNamePrefixes
                      formname={'personnelForm'}
                      placeholder={t('เลือกคำนำหน้าชื่อ')}
                      isfilterscreen={'true'}
                      disabled={formType === 'view' ? true : false}
                      handleChange={(e) => form.setFieldsValue({ namePrefixCode: e?.value })}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item name="genderCode" label={t('เพศ')}>
                    <SelectGenders
                      formname={'personnelForm'}
                      placeholder={t('เลือกเพศ')}
                      isfilterscreen={'true'}
                      disabled={formType === 'view' ? true : false}
                      handleChange={(e) => form.setFieldsValue({ genderCode: e?.value })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Item
                    name="firstNameTH"
                    label={t('ชื่อภาษาไทย')}
                    rules={[
                      { required: true, message: t('กรุณาระบุชื่อภาษาไทย') },
                      { pattern: regexThai, message: t(`กรุณากรอกด้วยภาษาไทยหรือตัวเลข ก-๙`) }
                    ]}
                  >
                    <InputNew disabled={formType === 'view' ? true : false} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    name="lastNameTH"
                    label={t('นามสกุลภาษาไทย')}
                    rules={[
                      { required: true, message: t('กรุณาระบุนามสกุลภาษาไทย') },
                      { pattern: regexThai, message: t(`กรุณากรอกด้วยภาษาไทยหรือตัวเลข ก-๙, 0-9`) }
                    ]}
                  >
                    <InputNew disabled={formType === 'view' ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Item
                    name="firstNameEN"
                    label={t('ชื่อภาษาอังกฤษ')}
                    rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z`) }]}
                  >
                    <InputNew disabled={formType === 'view' ? true : false} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    name="lastNameEN"
                    label={t('นามสกุลภาษาอังกฤษ')}
                    rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z`) }]}
                  >
                    <InputNew disabled={formType === 'view' ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                {/* <Col md={12}>
                  <Form.Item
                    name="dateOfBirth"
                    label={t('วัน/เดือน/ปี เกิด')}
                    rules={[{ required: true, message: t('กรุณาระบุวันเกิด') }]}
                  >
                    <Datepicker
                      minDate={dateStringToPicker(new Date(moment(new Date()).subtract(60, 'year')))}
                      maxDate={dateStringToPicker()}
                      disabled={formType === 'view' ? true : false}
                      setValueDateFn={async (data) => {
                        form.setFieldsValue({ dateOfBirth: await data })
                        setErrorField('dateOfBirth', false) // set false error field
                      }}
                      onClear={() => form.setFieldsValue({ dateOfBirth: null })}
                    />
                  </Form.Item>
                </Col> */}
                <Col md={12}>
                  <Form.Item name="responsibility" label={t('ผู้รับผิดชอบหลัก')} initialValue={0}>
                    <RadioGroup>
                      <Row>
                        <Radio disabled={formType === 'view' ? true : false} value={0}>
                          {t('ไม่ใช่')}
                        </Radio>
                        <Radio disabled={formType === 'view' ? true : false} value={1}>
                          {t('ใช่')}
                        </Radio>
                      </Row>
                    </RadioGroup>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <LabelNew type="sub-header-primary">{t('ข้อมูลการติดต่อ')}</LabelNew>
              </Row>
              <Row style={{ marginTop: 24 }}>
                <Col md={12}>
                  <Form.Item name="telephone" label={t('โทรศัพท์')}>
                    <InputNew disabled={formType === 'view' ? true : false} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item name="mobile" label={t('มือถือ')}>
                    <InputNew disabled={formType === 'view' ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Item name="emergency" label={t('เบอร์ติดต่อฉุกเฉิน')}>
                    <InputNew disabled={formType === 'view' ? true : false} />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item name="lineId" label={t('ไอดีไลน์')}>
                    <InputNew disabled={formType === 'view' ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Item
                    name="email"
                    label={t('อีเมล')}
                    rules={[
                      { type: 'required', required: true, message: t('กรุณาระบุอีเมล') },
                      { type: 'email', required: true, message: t('อีเมลไม่ถูกต้อง') }
                    ]}
                  >
                    <InputNew disabled={formType === 'view' ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="center">
                <Space size={[8, 8]} wrap>
                  {formType !== 'view' ? (
                    <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit" action={formType}>
                      {t('บันทึก')}
                    </ButtonNew>
                  ) : (
                    ''
                  )}
                  <ButtonNew type="back" onClick={() => navigate('/administrator/personnel')}>
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Space>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default PersonnelForm
