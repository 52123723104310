import { Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { Datatable } from '../../../../components/datatable/Datatable'
import { NoData } from '../../../../components/nodatagraph/NoData'

import { TableAmountStyled } from './css/TableAmountStyled'
import { Columns } from './utils/OverviewFollowUpAmountColumn'
import { useLazyQuery } from '@apollo/client'
import { GET_DATA_OVERVIEW_FOLLOWUP_AMOUNT } from './graphql/Query'
import { encryptInput } from '../../../../utilitys/crypto'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { Alert } from '../../../../components/alert/Alert'
import { useTranslation } from 'react-i18next'
import { displayError } from '../../../../utilitys/helper'

const Responsive = {
  md: 24,
  lg: 24,
}

const OverviewFollowUpAmount = (props) => {
  const { dataFilter, getProjectCode = () => {} } = props

  const { t } = useTranslation()

  const [dataRow, setdataRow] = useState([])

  // =================================== API =======================================
  const [callGraphAmountFnc, { data: dataGraph, loading: loadingGraph, error: errorGraph }] = useLazyQuery(
    GET_DATA_OVERVIEW_FOLLOWUP_AMOUNT,
  )

  useEffect(() => {
    if (errorGraph) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorGraph)),
        onOk() {},
      })
    }
  }, [errorGraph])

  useEffect(() => {
    if (dataGraph) {
      const res = dataGraph.getDataOverviewFollowUpAmount

      const group = _.groupBy(res, (items) => items.stepTypeCode)

      const data = _.mapValues(group, (value) => value[0])

      setdataRow([{ ...data, key: 'dataRow_1' }])
    }
  }, [dataGraph])

  // =================================== CAll API =======================================
  const { auditTeamCode, dateEnd, dateStart, jobStatusCode, organizationCode, stepTypeCode } = dataFilter

  useEffect(() => {
    setdataRow(null)
    const dataInput = {}

    if (auditTeamCode) dataInput['auditTeamCode'] = auditTeamCode
    if (dateEnd) dataInput['dateEnd'] = dateEnd
    if (dateStart) dataInput['dateStart'] = dateStart
    if (jobStatusCode) dataInput['jobStatusCode'] = jobStatusCode
    if (organizationCode) dataInput['organizationCode'] = organizationCode
    if (stepTypeCode) dataInput['stepTypeCode'] = stepTypeCode

    if (dataInput?.dateStart && dataInput?.dateEnd && dataInput?.organizationCode) {
      callGraphAmountFnc({ variables: { input: encryptInput(dataInput) } })
    }
  }, [auditTeamCode, dateEnd, dateStart, jobStatusCode, organizationCode, stepTypeCode])

  const handleClick = (projectCode) => {
    getProjectCode(projectCode)
  }

  return (
    <TableAmountStyled>
      <Card className="">
        <Row gutter={[16, 8]}>
          <Col {...Responsive}>
            {loadingGraph && <SpinnersSmaillNew />}
            {!_.isEmpty(dataRow) ? (
              <Datatable
                columns={Columns({ handleClick })}
                data={dataRow}
                searchCustom={false}
                paginationCustom={false}
                scroll={{ x: 1024 }}
                enableScroll={{ x: true, y: false }}
              />
            ) : (
              <NoData />
            )}
          </Col>
        </Row>
      </Card>
    </TableAmountStyled>
  )
}

export default OverviewFollowUpAmount
