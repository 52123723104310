import React, { useEffect, useState } from 'react'
import { Pie } from '@ant-design/plots'
import _ from 'lodash'
import { numberFormat } from '../../utilitys/helper'

export const GraphPie = (props) => {
  const { data, radius, onGetPieValue } = props

  const [dataGraph, setDataGraph] = useState([])
  const [configs, setConfigs] = useState({
    appendPadding: 12,
    data: [],
    angleField: 'value',
    colorField: 'type',
    color: ['#0C6ABF', '#EB895F'],
    radius: radius ? radius : 0.9,
    height: 300,
    label: {
      content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
      type: 'inner',
      offset: '-30%',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    tooltip: {
      formatter: (d) => {
        return { name: d.type, value: numberFormat(d.value) }
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        onGetPieValue(args[0]?.data?.data)
      })
    },
  })

  useEffect(() => {
    setConfigs({
      ...configs,
      data: dataGraph,
    })
  }, [dataGraph])

  useEffect(() => {
    setDataGraph(data)

    if (!_.isEmpty(data)) {
      const colors = []

      data.forEach((item) => {
        if (item.color) {
          colors.push(item.color)
        } else {
          if (item.type === 'NPL') {
            colors.push('#0C6ABF')
          }
          if (item.type === 'PL') {
            colors.push('#EB895F')
          }
        }
      })

      setConfigs({
        ...configs,
        color: colors,
      })
    }
  }, [data])

  return !_.isEmpty(dataGraph) ? <Pie {...configs} /> : null
}
