import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew as Input } from '../../../../components/input/Input'
import SelectProjectRefer from '../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import SelectAuditTypes from '../../../../components/inputfromapi/selectaudittypes/SelectAuditTypes'
import SelectPersonnels from '../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'

import { ButtonNew } from '../../../../components/button/Button'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import { ModalNew } from '../../../../components/modal/Modal'
import ModalGeneralRiskForm from './modal/ModalGeneralRiskForm'

const FormAuditProjectsGeneral = (props) => {
  const form = props.formGeneral
  const { t } = useTranslation()
  const [modalRiskForm, setModalRiskForm] = useState(false)
  const [modalRiskFormSubMit, setModalRiskFormSubMit] = useState(false)
  const [riskFormBuffer, setRiskFormBuffer] = useState({ code: null, name: '' })

  useEffect(() => {
    if (modalRiskFormSubMit) {
      props.setRiskForm(riskFormBuffer)
      form.setFieldsValue({ riskFormCode: riskFormBuffer.code + ' : ' + riskFormBuffer.name })
      setRiskFormBuffer({ code: null, name: '' })
      setModalRiskFormSubMit(false)
    }
  }, [modalRiskFormSubMit])

  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }
  return (
    <>
      <Row>
        <Col md={24}>
          <Form
            form={form}
            autoComplete="off"
            labelAlign="left"
            labelCol={{
              sm: 24,
              md: 8,
            }}
            wrapperCol={{
              sm: 24,
              md: 16,
            }}
            layout="horizontal"
            name="auditProjectGeneral"
            onFinish={props.onFinish}
          >
            <LabelNew type="body-header">{t('รายละเอียดโครงการ')}</LabelNew>
            <Row justify="center">
              <Col md={18}>
                <Form.Item
                  name="projectCode"
                  label={<LabelNew type="tab-header-inactive">{t('รหัสโครงการ')}</LabelNew>}
                  rules={[
                    { required: true, message: t('กรุณาระบุรหัสโครงการ') },
                    {
                      max: 10,
                      message: t('กรุณาระบุรหัสโครงการน้อยกว่าหรือเท่ากับ 10 ตัวอักษร'),
                    },
                  ]}
                >
                  <Input
                    maxLength={10}
                    placeholder={t('ระบุรหัสโครงการ')}
                    disabled={props.typeForm !== 'add' ? true : false}
                  />
                </Form.Item>
                <Form.Item name="projectNameTH" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item name="projectNameEN" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="projectName"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}
                  rules={[
                    { required: true, message: 'กรุณาระบุชื่อโครงการ' },
                    {
                      max: 200,
                      message: t('กรุณาระบุชื่อโครงการน้อยกว่าหรือเท่ากับ 200 ตัวอักษร'),
                    },
                  ]}
                >
                  <Input
                    maxLength={200}
                    placeholder={t('ระบุชื่อโครงการ')}
                    disabled={props.typeForm === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="projectTypeCode"
                  label={<LabelNew type="tab-header-inactive">{t('ประเภทโครงการ')}</LabelNew>}
                  initialValue={t('โครงการนอกแผน')}
                >
                  <Input placeholder={t('ระบุประเภทโครงการ')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="projectRefCode"
                  label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาเลือกแหล่งที่มาของโครงการ'),
                    },
                  ]}
                >
                  <SelectProjectRefer
                    filters={{ isActive: 1 }}
                    formname="auditProjectGeneral"
                    placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                    handleChange={(value) => {
                      form.setFieldsValue({ projectRefCode: value ? value.value : null })
                      if (value) {
                        setErrorField('projectRefCode', false)
                      } else {
                        setErrorField('projectRefCode', t('กรุณาเลือกแหล่งที่มาของโครงการ'))
                      }
                    }}
                    disabled={props.typeForm === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="auditTypeCode"
                  label={<LabelNew type="tab-header-inactive">{t('ประเภทการตรวจสอบ')}</LabelNew>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาเลือกประเภทการตรวจสอบ'),
                    },
                  ]}
                >
                  <SelectAuditTypes
                    filters={{ isActive: 1 }}
                    formname="auditProjectGeneral"
                    placeholder={t('เลือกประเภทการตรวจสอบ')}
                    handleChange={(value) => {
                      form.setFieldsValue({ auditTypeCode: value ? value.value : null })
                      if (value) {
                        setErrorField('auditTypeCode', false)
                      } else {
                        setErrorField('auditTypeCode', t('กรุณาเลือกประเภทการตรวจสอบ'))
                      }
                    }}
                    disabled={props.typeForm === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="riskFormCode"
                  label={<LabelNew type="tab-header-inactive">{t('แบบประเมินความเสี่ยง')}</LabelNew>}
                >
                  <Input
                    addonAfter={
                      <ButtonNew
                        type=""
                        htmlType="button"
                        onClick={() => {
                          setModalRiskForm(true)
                        }}
                        style={{
                          marginLeft: '-53px',
                          marginRight: '-11px',
                          marginTop: '-2px',
                          height: '38px',
                          zIndex: 99,
                        }}
                        disabled={props.typeForm === 'view' ? true : false}
                      >
                        {t('เลือก')}
                      </ButtonNew>
                    }
                    disabled={true}
                    placeholder={t('เลือกแบบประเมินความเสี่ยง')}
                  />
                </Form.Item>
                <Form.Item
                  name="approveStatusName"
                  label={<LabelNew type="tab-header-inactive">{t('สถานะอนุมัติ')}</LabelNew>}
                  initialValue={props.typeForm === 'add' ? t('รออนุมัติ') : ''}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="projectStatusName"
                  label={<LabelNew type="tab-header-inactive">{t('สถานะของโครงการ')}</LabelNew>}
                  initialValue={props.typeForm === 'add' ? t('รอดำเนินการ') : ''}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item name="cancelStatus" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="cancelRemark"
                  hidden={props.typeForm === 'add' || form.getFieldValue('cancelStatus') === 0 ? true : false}
                  label={<LabelNew type="tab-header-inactive">{t('หมายเหตุของการขอยกเลิกการตรวจสอบ')}</LabelNew>}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="approvePersonnelCode"
                  label={<LabelNew type="tab-header-inactive">{t('ผู้อนุมัติแผนการตรวจสอบ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุ') }]}
                >
                  <SelectPersonnels
                    placeholder={t('เลือกผู้อนุมัติแผนการตรวจสอบ')}
                    formname={'auditProjectGeneral'}
                    filters={{ levelCode: 12, organizationChecked: 2, personnaloperationCode: 10 }}
                    disabled={props.typeForm === 'view' ? true : false}
                    handleChange={(e) => form.setFieldsValue({ approvePersonnelCode: e?.value })}
                  />
                </Form.Item>
                <Form.Item
                  name="isActive"
                  label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  initialValue={1}
                >
                  <RadioGroup disabled={props.typeForm === 'view' ? true : false}>
                    <Space size={[8, 8]} wrap>
                      <Radio value={1}>{t('ใช้งาน')}</Radio>
                      <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <ModalNew
        visible={modalRiskForm}
        onSubmit={() => {}}
        onOk={() => {
          setModalRiskFormSubMit(true)
          setModalRiskForm(false)
        }}
        onCancel={() => {
          setRiskFormBuffer({ code: null, name: '' })
          setModalRiskForm(false)
        }}
        onClose={setModalRiskForm}
        testTitle={t('แบบประเมินความเสี่ยง')}
        btnName={t('เพิ่ม')}
        type="big"
      >
        <ModalGeneralRiskForm
          type={props.type}
          refCode={props.refCode}
          modalRiskForm={modalRiskForm}
          riskForm={props.riskForm}
          riskFormBuffer={riskFormBuffer}
          setRiskFormBuffer={setRiskFormBuffer}
        />
      </ModalNew>
    </>
    //okButtonProps={typeForm === 'view' ? { style: { display: 'none' } } : {}}
  )
}

export default FormAuditProjectsGeneral
