import { Card, Col, Form, Row } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Datepicker } from '../../../../components/datepicker/Datepicker.jsx'

import { IconNew } from '../../../../components/icon/Icon.jsx'
import SelectAuditTeams from '../../../../components/inputfromapi/selectauditteams/SelectAuditTeams.jsx'
import SelectJobStatus from '../../../../components/inputfromapi/selectjobstatus/SelectJobStatus.jsx'
import SelectOrganization from '../../../../components/inputfromapi/selectorganization/SelectOrganization.jsx'
import SelectStepTypes from '../../../../components/inputfromapi/selectsteptype/SelectStepType.jsx'
import { LabelNew } from '../../../../components/label/Label.jsx'
import { getFirstDayPresentYear, getLastDayPresentYear } from '../../../../utilitys/dateHelper.js'
import { FilterStyled } from './css/FilterStyled.js'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  inputfilter: {
    md: 8,
    lg: 8,
  },
}

const OverviewFollowUpFilter = (props) => {
  const { getValue = () => {} } = props

  const { t } = useTranslation()
  const [form] = Form.useForm()

  const formName = 'OverviewFollowUpFillter'
  const setErrorField = (name, errors) => {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  useEffect(() => {
    const field = {
      dateStart: getFirstDayPresentYear(),
      // dateStart: '2021-01-01',
      dateEnd: getLastDayPresentYear(),
      organizationCode: ['ORG0000014', 'ORG0000029', 'ORG0000030'],
    }
    form.setFieldsValue(field)
  }, [])

  const [filterData, setfilterData] = useState({
    dateStart: getFirstDayPresentYear(),
    // dateStart: '2021-01-01',
    dateEnd: getLastDayPresentYear(),
    organizationCode: ['ORG0000014', 'ORG0000029', 'ORG0000030'],
    auditTeamCode: null,
    jobStatusCode: null,
    stepTypeCode: null,
  })

  useEffect(() => {
    const { dateStart, dateEnd, organizationCode } = filterData

    if (dateStart && dateEnd && !_.isEmpty(organizationCode)) {
      getValue(filterData)
    } else {
      // เช็ค require
      if (dateStart) {
        setErrorField('dateStart', false)
      } else {
        setErrorField('dateStart', 'กรุณาเลือกวันที่เริ่มต้น')
      }
      if (dateEnd) {
        setErrorField('dateEnd', false)
      } else {
        setErrorField('dateEnd', 'กรุณาเลือกวันที่สิ้นสุด')
      }
      if (!_.isEmpty(organizationCode)) {
        setErrorField('organizationCode', false)
      } else {
        setErrorField('organizationCode', 'กรุณาเลือกสายงานตรวจสอบ')
      }
    }
  }, [
    filterData.dateStart,
    filterData.dateEnd,
    filterData.organizationCode,
    filterData.auditTeamCode,
    filterData.jobStatusCode,
    filterData.stepTypeCode,
  ])

  return (
    <Row gutter={[16, 16]}>
      <Col {...Responsive}>
        <FilterStyled>
          <Card
            className="card-dash card-filter"
            title={
              <>
                <span className="card-title-icon">
                  <IconNew icon={'Filter'} size={17} />
                </span>
                <span className="card-title-text">{t('คัดกรองข้อมูล')}</span>
              </>
            }
          >
            <Form form={form} name={formName} layout="vertical">
              <Row gutter={[16, 8]}>
                <Col {...Responsive.inputfilter}>
                  <Form.Item
                    name="dateStart"
                    rules={[{ required: true, message: t('กรุณาเลือกวันที่เริ่มต้น') }]}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={(data) => {
                        form.setFieldsValue({
                          dateStart: data,
                        })
                        setfilterData({ ...filterData, dateStart: data })
                      }}
                      onChange={() => {}}
                      onClear={() => {
                        form.setFieldsValue({ dateStart: null })
                        setfilterData({ ...filterData, dateStart: null })
                      }}
                      autoComplete="off"
                      placeholder={t('เลือกวันที่เริ่มต้น')}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive.inputfilter}>
                  <Form.Item
                    name="organizationCode"
                    label={<LabelNew type="tab-header-inactive">{t('สายงานตรวจสอบ')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาเลือกสายงานตรวจสอบ') }]}
                  >
                    <SelectOrganization
                      multiple={true}
                      formname={formName}
                      placeholder={t('เลือกสายงานตรวจสอบ')}
                      filters={{ organizationChecked: 2, isActive: 1 }}
                      handleChange={(data) => {
                        form.setFieldsValue({ organizationCode: _.isEmpty(data) ? [] : data })
                        setfilterData({ ...filterData, organizationCode: _.isEmpty(data) ? null : data })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive.inputfilter}>
                  <Form.Item name="auditTeamCode" label={t('ทีม')}>
                    <SelectAuditTeams
                      filters={{ isActive: 1 }}
                      placeholder={t('เลือกทีม')}
                      formname={formName}
                      handleChange={(value) => {
                        form.setFieldsValue({ auditTeamCode: value ? value.value : null })
                        setfilterData({ ...filterData, auditTeamCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col {...Responsive.inputfilter}>
                  <Form.Item
                    name="dateEnd"
                    rules={[{ required: true, message: t('กรุณาเลือกวันที่สิ้นสุด') }]}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={(data) => {
                        form.setFieldsValue({
                          dateEnd: data,
                        })
                        setfilterData({ ...filterData, dateEnd: data })
                      }}
                      onClear={() => {
                        setfilterData({ ...filterData, dateEnd: null })
                        form.setFieldsValue({ dateEnd: null })
                      }}
                      placeholder={t('เลือกวันที่สิ้นสุด')}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive.inputfilter}>
                  <Form.Item name="jobStatusCode" label={t('สถานะดำเนินการ')}>
                    <SelectJobStatus
                      placeholder={t('เลือกสถานะดำเนินการ')}
                      formname={formName}
                      filters={{ isActive: 1 }}
                      handleChange={(e) => {
                        form.setFieldsValue({ jobStatusCode: e?.value })
                        setfilterData({ ...filterData, jobStatusCode: e?.value })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive.inputfilter}>
                  <Form.Item name="stepTypeCode" label={t('ขั้นตอน')}>
                    <SelectStepTypes
                      formname={formName}
                      placeholder={t('เลือกขั้นตอน')}
                      handleChange={(data) => {
                        const value = data ? data.value : null
                        form.setFieldsValue({ stepTypeCode: value })
                        setfilterData({ ...filterData, stepTypeCode: value })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </FilterStyled>
      </Col>
    </Row>
  )
}

export default OverviewFollowUpFilter
